import { constants as types } from "./types";
import { SyncDate } from "../../utils/time";

function getInitialViewportTransform() {
  const viewportTransform = localStorage.getItem("viewportTransform");
  if (!viewportTransform) {
    return {
      x: 0,
      y: 0,
      scale: 1,
    };
  }

  return JSON.parse(viewportTransform);
}

const initialState = {
  activeScreen: null,
  localTime: SyncDate.now(),
  viewportTransform: getInitialViewportTransform(),
  currentIndex: 0,
  splitIndexFirst: 0,
  splitIndexSecond: 0,
  currentContent: null,
  currentViewContent: "",
  additionalCurrentViewContent: "",
  currentViewContentData: [],
  restructuredPlaylistContent: null,
};

const actionMap = {
  [types.SET_RESTRUCTURED_PLAYLIST_CONTENT]: (state, action) => ({
    ...state,
    restructuredPlaylistContent: action.payload.playlistContent,
  }),
  [types.SET_ACTIVE_SCREEN]: (state, action) => ({
    ...state,
    activeScreen: action.payload.screen,
  }),
  [types.SET_LOCAL_TIME]: (state) => ({
    ...state,
    localTime: SyncDate.now(),
  }),
  [types.SET_CURRENT_CONTENT]: (state, action) => ({
    ...state,
    currentContent: action.payload.currentContent,
  }),
  [types.SET_CURRENT_THEATRE]: (state, action) => ({
    ...state,
    currentTheatre: action.payload.currentTheatre,
  }),
  [types.SET_CURRENT_PLAYLIST]: (state, action) => ({
    ...state,
    currentPlaylist: action.payload.currentPlaylist,
  }),
  [types.SET_CURRENT_INDEX]: (state, action) => {
    let indexType = "";
    switch (action.payload.type) {
      case "default":
        indexType = "currentIndex";
        break;
      case "splitFirst":
        indexType = "splitIndexFirst";
        break;
      case "splitSecond":
        indexType = "splitIndexSecond";
        break;
      default:
        indexType = "currentIndex";
        break;
    }
    return {
      ...state,
      [indexType]: action.payload.currentIndex,
    };
  },
  [types.SET_CURRENT_VIEW_CONTENT]: (state, action) => ({
    ...state,
    currentViewContent: action.payload.content,
  }),
  [types.SET_ADDITIONAL_CURRENT_VIEW_CONTENT]: (state, action) => ({
    ...state,
    additionalCurrentViewContent: action.payload.content,
  }),
  [types.SET_CURRENT_VIEW_CONTENT_DATA]: (state, { payload }) => ({
    ...state,
    currentViewContentData:
      payload && payload.length > 0 ? payload : state.currentViewContentData,
  }),
  [types.SET_FIRST_SPLIT_CONTENT]: (state, action) => ({
    ...state,
    splitSynchContentFirst: action.payload.splitSynchContentFirst,
  }),
  [types.SET_SECOND_SPLIT_CONTENT]: (state, action) => ({
    ...state,
    splitSynchContentSecond: action.payload.splitSynchContentSecond,
  }),
  [types.SET_VIEWPORT_TRANSFORM]: (state, { payload: viewportTransform }) => ({
    ...state,
    viewportTransform,
  }),
  [types.VIEWPORT_RESET_SCALE]: (state) => ({
    ...state,
    viewportTransform: {
      x: 0,
      y: 0,
      scale: 1,
    },
  }),
};

export default function view(state = initialState, action) {
  const reduceFn = actionMap[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
