import arrayIdInjector from "./array-id-injector";

const getPlaylistMeta = (entityId, playlists) => {
  const playlist = playlists.find((pl) => pl.id === entityId);
  return playlist.contentMeta;
};

const addContentMetaToPlaylist = (screensResp, playlistsResp) => {
  const setMetaToPlaylist = (contentMeta) => {
    const isPlaylist = ({ entityType }) => entityType === "playlist";
    const addedPlaylists = new Map();

    const iter = (arr, level) => {
      const sortedArr = arr.slice().sort((a, b) => a.order - b.order);
      return sortedArr.map((content) => {
        if (isPlaylist(content)) {
          const { entityId } = content;

          if (addedPlaylists.has(entityId)) {
            const addedPlaylist = addedPlaylists.get(entityId);
            if (addedPlaylist.level !== level) {
              return {
                ...content,
                contentMeta: [],
              };
            }
          }

          addedPlaylists.set(entityId, {
            id: entityId,
            level,
          });

          const nestedContentMeta = arrayIdInjector(
            iter(getPlaylistMeta(entityId, playlistsResp), level + 1),
            entityId
          );

          return {
            ...content,
            contentMeta: nestedContentMeta,
          };
        }

        return content;
      });
    };

    return iter(contentMeta, 0);
  };

  const newScreens = screensResp.map((screen) => {
    const changePlaylists = (playlists) =>
      playlists.map((playlist) => {
        if (playlist) {
          const newContentMeta = setMetaToPlaylist(playlist.contentMeta);

          const { contentMeta, ...rest } = playlist;
          return { ...rest, contentMeta: newContentMeta };
        } else {
          console.error("playlist.contentMeta undefined");
        }
      });

    const { playlists, ...rest } = screen;
    return { ...rest, playlists: changePlaylists(playlists) };
  });

  return newScreens;
};

export default addContentMetaToPlaylist;
