import React, { useState, useRef, useEffect, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const buttonStyle = {
  color: "white",
  border: "1px solid white",
  fontSize: 35,
  padding: 20,
};
const ACTIONS = {
  MOVE_LEFT: "MOVE_LEFT",
  MOVE_RIGHT: "MOVE_RIGHT",
  MOVE_UP: "MOVE_UP",
  MOVE_DOWN: "MOVE_DOWN",
  SCALE_UP: "SCALE_UP",
  SCALE_DOWN: "SCALE_DOWN",
};

export default ({
  handleScaleUp,
  handleScaleDown,
  handleScaleReset,
  handleUp,
  handleDown,
  handleLeft,
  handleRight,
}) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const repeatActionIntervalRef = useRef(null);
  const [action, setAction] = useState(null);

  const startAction = () => {
    repeatActionIntervalRef.current = setInterval(() => {
      switch (action) {
        case ACTIONS.MOVE_UP:
          handleUp();
          break;
        case ACTIONS.MOVE_DOWN:
          handleDown();
          break;
        case ACTIONS.MOVE_LEFT:
          handleLeft();
          break;
        case ACTIONS.MOVE_RIGHT:
          handleRight();
          break;
        case ACTIONS.SCALE_UP:
          handleScaleUp();
          break;
        case ACTIONS.SCALE_DOWN:
          handleScaleDown();
          break;

        default:
          break;
      }
      // Replace this with your desired action or function
    }, 50); // Adjust the interval as needed
  };

  const stopAction = () => {
    console.log("Action stopped!");
    clearInterval(repeatActionIntervalRef.current);
  };

  useEffect(() => {
    if (isMouseDown) {
      startAction();
    } else {
      stopAction();
    }

    // Cleanup the interval on component unmount
    return () => {
      stopAction();
    };
  }, [isMouseDown]);

  const handleRepeatingAction = (_action) => {
    setAction(_action);
    setIsMouseDown(true);
  };

  return (
    <>
      {" "}
      <Grid
        container
        justify="center"
        alignItems="center"
        onMouseUp={() => setIsMouseDown(false)}
        onMouseLeave={() => setIsMouseDown(false)}
      >
        <Grid item xs={12}>
          {" "}
          <Button
            onMouseDown={() => handleRepeatingAction(ACTIONS.MOVE_UP)}
            onMouseUp={() => setIsMouseDown(false)}
            style={buttonStyle}
          >
            Up
          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2} style={{ textAlign: "right" }}>
          <Button
            onMouseDown={() => handleRepeatingAction(ACTIONS.MOVE_LEFT)}
            onMouseUp={() => setIsMouseDown(false)}
            style={buttonStyle}
          >
            Left
          </Button>
        </Grid>{" "}
        <Grid item xs={2} style={{ textAlign: "left" }}>
          {" "}
          <Button
            onMouseDown={() => handleRepeatingAction(ACTIONS.MOVE_RIGHT)}
            onMouseUp={() => setIsMouseDown(false)}
            style={buttonStyle}
          >
            Right
          </Button>
        </Grid>{" "}
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Button
            onMouseDown={() => handleRepeatingAction(ACTIONS.MOVE_DOWN)}
            onMouseUp={() => setIsMouseDown(false)}
            style={buttonStyle}
          >
            Down
          </Button>
        </Grid>
      </Grid>
      <div
        style={{ marginTop: 20 }}
        onMouseUp={() => setIsMouseDown(false)}
        onMouseLeave={() => setIsMouseDown(false)}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              onMouseDown={() => handleRepeatingAction(ACTIONS.SCALE_UP)}
              onMouseUp={() => setIsMouseDown(false)}
              style={buttonStyle}
            >
              Scale Up
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              onMouseDown={() => handleRepeatingAction(ACTIONS.SCALE_DOWN)}
              onMouseUp={() => setIsMouseDown(false)}
              style={buttonStyle}
            >
              Scale Down
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button onClick={() => handleScaleReset()} style={buttonStyle}>
              Reset Scale
            </Button>{" "}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
