import _ from "lodash";
import { constants as types } from "./types";

const initialState = {
  contentItems: [],
  availableContentItems: [],
  itemsToDownload: [],
  extractedHtmlImages: [],
  refreshIndex: 0,
  downloadIndex: 0,
};

const actionMap = {
  [types.FETCH_USER_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      contentItems: _.uniqBy(
        payload.contentGroup
          .filter((item) => {
            if (!item.content[0]) return false;
            const { fileName, type } = item.content[0];

            return (
              fileName &&
              (type === "html" || type === "image" || type === "video")
            );
          })
          .map((item) => {
            const { fileName, type, content, hash, updated } = item.content[0];
            const { name } = item;

            return {
              name,
              fileName,
              type,
              content,
              hash,
              updated,
              id: item.id,
            };
          })
      ),
    };
  },
  [types.INCREMENT_RESOURCE_REFRESH_INDEX]: (state) => {
    return { ...state, refreshIndex: state.refreshIndex + 1 };
  },
  [types.INCREMENT_RESOURCE_DOWNLOAD_INDEX]: (state) => {
    return { ...state, downloadIndex: state.downloadIndex + 1 };
  },
  [types.SET_AVAILABLE_CONTENT_ITEMS]: (state, { payload }) => {
    return {
      ...state,
      availableContentItems: _.sortBy(
        _.uniqBy(
          _.concat(
            payload.filter((i) => i != null),
            state.availableContentItems
          ),
          "content"
        ),
        "name"
      ),
    };
  },

  [types.SET_EXTRACTED_HTML_IMAGES]: (state, { payload }) => {
    //  console.log({ state: state.extractedHtmlImages, payload });

    return {
      ...state,
      extractedHtmlImages: _.union(state.extractedHtmlImages, payload),
    };
  },
};

export default function resourcesReducer(state = initialState, action) {
  const reduceFn = actionMap[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
