import _ from "lodash";
import { safeGet } from ".";
import { hasContentWithoutPlaytime } from "./has-content-without-playtime";
import { SyncDate } from "./time";
import arrayIdInjector from "./array-id-injector";

const { isArray } = Array;
const emptyArr = [];

// const debug = (...messages) => console.log('cfp: ', ...messages);

function checkDate(playtime, startTime) {
  const startDate = `${playtime.date} ${playtime.start}:00`;
  const endDate = `${playtime.date} ${playtime.end}:59`;
  const currentDate = SyncDate.getMoment(startTime).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  return startDate <= currentDate && currentDate <= endDate;
}

const cloneDeep = (arr) =>
  arr.map((item) => {
    if (Array.isArray(item)) {
      return arrayIdInjector(cloneDeep(item), item.id);
    }
    return { ...item };
  });

export default function (
  contentMeta,
  startPlaylistTime,
  endPlaylistTime,
  screenType
) {
  // debug('contentMeta', contentMeta);

  if (!hasContentWithoutPlaytime(contentMeta, screenType)) {
    return emptyArr;
  }

  const filledMeta = cloneDeep(contentMeta);

  const flatList = [];
  let inx = 0;
  let startTime = startPlaylistTime;

  // eslint-disable-next-line
  const canAddToSchedule = (cm) => {
    const playtime = safeGet(() => cm._playtime) || [];
    return (
      !playtime.length ||
      playtime.find((playtime) => checkDate(playtime, startTime))
    );
  };

  function incrementIndex(current, arr) {
    return current + 1 >= arr.length ? 0 : current + 1;
  }

  function scheduleItemFactory(content, arr) {
    const {
      type,
      name,
      content: contentGroupContentList = [],
    } = content.entity;

    if (!canAddToSchedule(content)) return;
    if (screenType !== "theatre" && type === "programme") return;

    const { duration = 15, _playtime, isFullScreen } = content;
    const durationMs = duration * 1000;

    const clientTimeZoneOffset = SyncDate.getTimezoneOffset();
    // console.log({ clientTimeZoneOffset });
    const startTimeDateObj = new Date(startTime);

    // if (startTimeDateObj.getHours() < 14 && startTimeDateObj.getHours() > 10)
    //   console.log({
    //     name,
    //     clientTimeZoneOffset,
    //     startTimeDateObjOffset: startTimeDateObj.getTimezoneOffset(),

    //     startTimeDateObjTime: startTimeDateObj.getTime(),
    //     momentFormatted: SyncDate.getMoment(startTimeDateObj.getTime()).format(
    //       "HH:mm:ss"
    //     ),
    //     order: inx,
    //   });

    const scheduleItem = {
      type,
      duration: durationMs,
      _playtime,
      isFullScreen,
      content: content.content === "programme" ? content.entityId: content.content,
      playlistId: content.playlistId,
      programmeId: content.content === "programme" ? content.entityId: undefined,
      name: name || "Full Programme",
      date: SyncDate.getMoment(startTime).format("dddd"),
      timeStart: SyncDate.getMoment(startTime).format("HH:mm:ss"),
      timeEnd: SyncDate.getMoment(startTime + durationMs).format("HH:mm:ss"),
      timeStartMs: startTime,
      timeEndMs: startTime + durationMs,
      timeStartWithOffset: SyncDate.getMoment(startTimeDateObj.getTime()),
      timeEndWithOffset: SyncDate.getMoment(
        durationMs + startTimeDateObj.getTime()
      ),
      order: inx,
      contentIds: contentGroupContentList.map((c) => c.id),
    };

    // TODO: ###################

    // scheduleItem.timeStart = scheduleItem.timeStartWithOffset;
    // scheduleItem.timeEnd = scheduleItem.timeEndWithOffset;

    // ######################

    inx += 1;
    startTime += durationMs;
    arr.push(scheduleItem);
  }

  /* eslint-disable no-param-reassign */
  function getContentIndex(parent, playlistId) {
    const initialContentIndex = 0;

    if (!parent.contentIndexes) {
      parent.contentIndexes = { [playlistId]: initialContentIndex };
    } else if (!parent.contentIndexes[playlistId]) {
      parent.contentIndexes[playlistId] = initialContentIndex;
    }

    return parent.contentIndexes[playlistId];
  }

  function setContentIndex(parent, playlistId, newIndex) {
    parent.contentIndexes[playlistId] = newIndex;
  }
  /* eslint-enable */

  function nestedFiller(content, parent) {
    // const d = (...m) => debug('nestedFiller: ', ...m);

    // d('content', content);
    // d('parent', parent);

    if (!isArray(content)) {
      scheduleItemFactory(content, flatList);
      return;
    }

    // if (!hasContentWithoutPlaytime(content, screenType)) return;
    if (content.every((c) => !canAddToSchedule(c))) return;

    const contentIndex = getContentIndex(parent, content.id);
    const nestedContent = content[contentIndex];

    const newContentIndex = incrementIndex(contentIndex, content);
    setContentIndex(parent, content.id, newContentIndex);

    const canNotTakeNestedContent =
      !isArray(nestedContent) && !canAddToSchedule(nestedContent);

    const nextContent = canNotTakeNestedContent ? content : nestedContent;
    const nextParent = canNotTakeNestedContent ? parent : content;
    nestedFiller(nextContent, nextParent);
  }

  function flatListFiller(cmArr) {
    cmArr.forEach((content) => {
      if (isArray(content)) {
        nestedFiller(content, cmArr);
      } else {
        scheduleItemFactory(content, flatList);
      }
    });
  }

  while (startTime <= endPlaylistTime) {
    flatListFiller(filledMeta);
  }

  return flatList;
}
