import React, { PureComponent } from "react";
import throttle from "lodash/throttle";
import Slide from "../Slide";
import styled from "styled-components";

const StyledDefaultScreen = styled.div`
  &.container {
    background-color: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    text-align: center;
  }
`;

export default class DefaultScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.slideRef = React.createRef();
    // debugger;
    this.throttledSync = throttle(this.sync, 1000);
  }
  state = { opacity: 0 };
  componentDidMount() {
    // this.throttledSync();
  }

  componentDidUpdate(prevProps) {
    const { currentPlaylistIndex } = this.props;

    const { currentPlaylistIndex: prevCurrentPlaylistIndex } = prevProps;

    if (prevCurrentPlaylistIndex !== currentPlaylistIndex) {
      this.throttledSync();
    }
  }
  componentWillUnmount() {
    const { setCurrentIndex, screenType } = this.props;

    setCurrentIndex(0, screenType);

    clearTimeout(this.startSlideShow);
    this.startSlideShow = null;
    this.throttledSync.cancel();
  }

  sync = () => {
    this.props.synchronizeContentNoApiCall().then(() => {
      clearTimeout(this.startSlideShow);
      this.startSlideShow = null;

      this.setViewContent();
      this.runRefreshContent();
    });
  };

  setViewContent = () => {
    const {
      playlistContent,
      currentPlaylistIndex,

      currentContent,
    } = this.props;

    if (!playlistContent || !playlistContent.length) {
      return null;
    }

    const viewContent =
      playlistContent[currentPlaylistIndex] || playlistContent[0];

    if (!viewContent || !viewContent.duration) {
      return null;
    }
    // console.log({ playlistContent, viewContent, currentContent });
    this.togglerSlide(playlistContent, {
      ...viewContent,
      duration:
        (currentContent && currentContent.duration) || viewContent.duration,
    });
  };

  animateTransition = () => {
    this.animateOpacity = setInterval(() => {
      const { opacity } = this.state;

      const result = opacity + 0.05;
      this.setState({
        opacity: result,
      });
      if (opacity >= 1) {
        this.setState({
          opacity: 1,
        });
        clearInterval(this.animateOpacity);
      }
    }, 1000 / 25);
  };

  togglerSlide = (playlistContent, viewContent) => {
    const {
      setCurrentIndex,
      currentPlaylistIndex,
      setCurrentViewContent,
      currentViewContentData,
      screenType,
    } = this.props;

    this.setState({
      opacity: 0,
    });
    clearInterval(this.animateOpacity);
    this.animateTransition();

    setCurrentViewContent(viewContent);
    this.runRefreshContent();
    clearTimeout(this.startSlideShow);

    this.startSlideShow = null;

    this.startSlideShow = setTimeout(() => {
      if (currentPlaylistIndex >= playlistContent.length - 1) {
        setCurrentIndex(0, screenType);
        return null;
      }

      setCurrentIndex(currentPlaylistIndex + 1, screenType);
    }, viewContent.duration);
  };

  runRefreshContent = () => {
    const getChildElement =
      (this.slideRef.current && this.slideRef.current.firstChild) || "empty";

    // console.log({
    //   slideRef: this.slideRef.current,
    //   child: getChildElement.localName,
    //   firstChild: this.slideRef.current.firstChild,
    // });
    switch (getChildElement.localName) {
      case "video": {
        const { currentContent } = this.props;
        // const viewContent = contentMeta[currentIndex];
        const currentTime = (currentContent || {}).remainder / 1000 || 0;
        getChildElement.currentTime = currentTime;
        getChildElement.load();
        // getChildElement.play();
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { currentViewContent, playlistContent, currentViewContentData, enabledSound } =
      this.props;

    const { opacity } = this.state;

    if (!playlistContent || playlistContent.length === 0) return false;

    //TODO code repetition, see func above
    const currentContent = currentViewContent || playlistContent[0];

    if (currentContent.content === "programme") return false;

    return (
      <StyledDefaultScreen className="container">
        <div
          style={{
            opacity,
            background: "black",
            width: "100%",
            height: "100%",
          }}
        >
          <Slide
            ref={this.slideRef}
            viewContent={currentContent.content}
            currentViewContentData={currentViewContentData}
            enabledSound={enabledSound}
          />
        </div>
      </StyledDefaultScreen>
    );
  }
}

DefaultScreen.defaultProps = {
  screenType: "default",
};
