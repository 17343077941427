import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import './fonts.css';

import * as appActions from "./redux/actions/app";

import ViewPage from "./containers/ViewPage";

import ModalSelector from "./components/common/modal/ModalSelector";

import history from "./history";

const { syncClockWithServer } = appActions;
class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { syncClockWithServer } = this.props;
    syncClockWithServer();
    this.syncClockWithServer = setInterval(syncClockWithServer, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.resetClockInterval);
  }

  render() {
    return (
      <Router history={history}>
        <ModalSelector />
        <Switch>
          <Route exact path="/">
            EventIgnite Web Player
          </Route>
          <Route exact path="/:access_code" component={ViewPage} />
          <Route exact path="/:access_code/screen/:id" component={ViewPage} />
          <Route
            exact
            path="/:access_code/screen/:id/:mode"
            component={ViewPage}
          />
        </Switch>
      </Router>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      syncClockWithServer,
    },
    dispatch
  );
}
export default connect(null, mapDispatchToProps)(App);
