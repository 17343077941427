import styled from 'styled-components';

const StyledSessionList = styled.div`
  display: flex;
  flex-flow: column;
  background-color: #fff;
  overflow: hidden;
  height: 100%;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
    animation: fadeIn 0.3s ease-in;

  .endText {
    height: 100%;
    background-color: rgb(255, 255, 255);
    color: rgb(62, 62, 62);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: ${(props) => props.fontFamily};
    font-size: 1em;
  }
`;

export { StyledSessionList };
