import { combineReducers } from "redux";

import screenReducer from "./screenReducer";
import viewReducer from "./viewReducer";
import appReducer from "./appReducer";
import userReducer from "./userReducer";
import programmesReducer from "./programmes";
import modalReducer from "./modalReducer";
import resourcesReducer from "./resourcesReducer";

export default combineReducers({
  screens: screenReducer,
  programmes: programmesReducer,
  view: viewReducer,
  resources: resourcesReducer,
  app: appReducer,
  user: userReducer,
  modal: modalReducer,
});
