import { constants as types } from "./types";

const initialState = {
  openModal: null,
  modalOptions: {},
};

const actionMap = {
  [types.SET_MODAL_OPEN_SCREEN_SELECT]: (state, { payload }) => {
    return {
      ...state,
      openModal: "selectScreenModal",
      modalOptions: payload,
    };
  },
  [types.CLOSE_MODAL]: () => {
    return { openModal: null, modalOptions: {} };
  },
};

export default (state = initialState, action) => {
  const reduceFn = actionMap[action.type];
  return reduceFn ? reduceFn(state, action) : state;
};
