import arrayIdInjector from "./array-id-injector";

// export const getNestedContentMeta = (screen) => {
//   // function getPlaylistIds(playlist) {
//   //   if (playlist.type === 'theatre') return [playlist.options.selectedPlaylistId];
//   //   if (playlist.type === 'split') return [playlist.options.firstPlaylistId, playlist.options.secondPlaylistId];
//   //   return [playlist.id];
//   // }

//   function mapContent(arr) {
//     const sortedArr = arr.sort((a, b) => a.order - b.order);
//     return sortedArr.map(content => {
//       if (content.entityType === 'playlist') {
//         // const nestedPlaylist = playlistContents.find(p => p.id === content.entityId);
//         return mapContent(content.contentMeta);
//       }
//       return content;
//     });
//   }

//   // const playlistIds = getPlaylistIds(screen.playlists[0]);
//   // const playlistsWithMeta = playlistIds.map(id => playlistContents.find(p => p.id === id));
//   // return playlistsWithMeta.map(plMeta => mapContent(plMeta.contentMeta));
//   return screen.playlists.map(playlist => mapContent(playlist.contentMeta));
// }

const getNestedContentMeta = (contentMeta) => {
  const sortedArr = contentMeta.sort((a, b) => a.order - b.order);
  return sortedArr.map(cm => {
    if (cm.entityType === 'playlist') {
      // const nestedPlaylist = playlists.find(p => p.id === cm.entityId);
      return arrayIdInjector(getNestedContentMeta(cm.contentMeta), cm.entityId);
    }
    return cm;
  });
}

export default getNestedContentMeta;
