import React from "react";
import Clock from "../../common/Clock";
import { StyledHeaderTitle, StyledProgrammeHeader } from "./styles";
import LandscapeClock from "../../common/LandscapeClock";

const COLUMN_TYPES = {
  CLOCK: 'CLOCK',
  HTML: 'HTML',
  IMAGE: 'IMAGE',
  BLANK: 'BLANK',
}

export default function ProgrammeHeader({
  fontSize,
  fontColor: colorText,
  backgroundColor,
  title,
  currentTime,
  day,
  fontFamily,
  isFullScreen,
  columnDistribution,
  columns,
  isLandscape,
}) {

  const headerRef = React.useRef(null);

  const renderColumn = (column) => {
    const { type } = column;
    switch (type) {
      case COLUMN_TYPES.CLOCK: return renderClockColumn();
      case COLUMN_TYPES.HTML: return renderHtmlColumn();
      case COLUMN_TYPES.IMAGE: return renderImageColumn(column);
      case COLUMN_TYPES.BLANK: return renderBlankColumn();
    }
  }

  const renderClockColumn = () => {
    return (
      <>
      {isLandscape ? (<LandscapeClock
        day={day}
        colorText={colorText}
        currentTime={currentTime}
        fontFamily={fontFamily}
      />):(<Clock
        day={day}
        colorText={colorText}
        currentTime={currentTime}
        fontFamily={fontFamily}
      />)}
      </>
      );
  }

  const renderHtmlColumn = () => {
    return (
      <StyledHeaderTitle
        fontFamily={fontFamily}
        colorText={colorText}
        fontSize={fontSize}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    );
  }

  const renderImageColumn = (column) => {
    const imageStyle = isLandscape ?  { height: headerRef.current?.offsetHeight, width: 'auto' } : { width: '100%' };
    const wrapperStyle = isLandscape ? { width: '100%',height: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' } : { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' };
    return (
      <div style={wrapperStyle}>
        <img src={column.content} style={imageStyle} />
      </div>);
  }

  const renderBlankColumn = () => {
    return (<div />);
  }

  return (
    <StyledProgrammeHeader
    ref={headerRef}
      columnDistribution={columnDistribution}
      colorText={colorText}
      backgroundColor={backgroundColor}
    >
      {!isFullScreen && (
        <>
          {columns ? columns.map((column) => renderColumn(column)) :
            (
              <>
                <Clock
                  day={day}
                  colorText={colorText}
                  currentTime={currentTime}
                  fontFamily={fontFamily}
                />
                <StyledHeaderTitle
                  fontFamily={fontFamily}
                  colorText={colorText}
                  fontSize={fontSize}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </>
            )}
        </>
      )}
    </StyledProgrammeHeader>
  );
}
