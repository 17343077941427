const getHtmlWithFonts = (htmlString, { rootFontSize, fontFamily }) => {
  const parser = new DOMParser();
  const htmlElement = parser.parseFromString(htmlString, "text/html");

  const body = htmlElement.querySelector("body");
  const hasBody = body.innerHTML !== "";

  const cssText = `
       ul {
      
      }
      body {
   
        font-family: ${fontFamily};
      }
    `;

  const style = document.createElement("style");
  style.innerHTML = cssText;

  // iframe.contentWindow.loadFonts = loadFonts;
  // iframe.contentWindow.loadFonts(iframeDocument);

  // htmlElement.head.appendChild(style);

  htmlElement.body.style = "font-family: ${fontFamily};";

  const htmlText = htmlElement.firstChild.outerHTML;

  return { htmlText, hasBody };
};

export default getHtmlWithFonts;
