import React, { Component } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";

const PLAYER_URL = process.env.PLAYER_URL;

const styles = (theme) => ({
  listItem: {
    border: "1px solid white",

    "&:hover": {
      cursor: "pointer",
    },
  },
});

class ScreenSelect extends Component {
  switchScreen = (screen) => {
    const { accessCode, screenMode } = this.props;

    if (!screenMode) {
      window.location.href = `/${accessCode}/screen/${screen.id}${window.location.search}`;
    } else {
      window.location.href = `/${accessCode}/screen/${screen.id}/${screenMode}${window.location.search}`;
    }
  };

  render() {
    const { screensList, classes } = this.props;
    return (
      <div style={{ maxHeight: 1300, overflowY: "scroll" }}>
        <List>
          <Grid container>
            {screensList.map((screen) => {
              return (
                <Grid item xs={6} key={screen.id}>
                  <ListItem
                    className={classes.listItem}
                    onClick={() => this.switchScreen(screen)}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          type="body2"
                          style={{ color: "#FFFFFF", fontSize: 25, padding: 1 }}
                        >
                          {screen.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(ScreenSelect);
