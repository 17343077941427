import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import _ from "lodash";

import { formatReadable } from "../../../utils/time";
import history from "../../../history";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import CloseIcon from "@material-ui/icons/Close";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";

import AvailableContent from "./AvailableContent";
import UpcomingContent from "./UpcomingContent";
import ScreenSelect from "./ScreenSelect";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { withStyles } from "@material-ui/styles";

import ImageControls from "./ImageControls";
import "./styles.css";

function launchIntoFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
}

function exitFullscreen() {
  if (document && document.fullscreenElement && document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document && document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document && document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}

const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#ff5722",
      light: "#fff",
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth,
  },
});

const styles = (theme) => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    padding: 0,
    cursor: "pointer",
    height: "100%",
    width: "100%",
    color: "white",
    zIndex: 100,
    fontSize: 40,
  },
  wrapper: {
    padding: 0,
  },
  textField: {
    border: "1px solid white",
    color: "white",
    background: "rgba(0, 0, 0, 0.7)",
    minWidth: 200,
    fontSize: 50,
  },
  outlinedButton: {
    color: "white",
    border: "1px solid white",
    fontSize: 35,
    padding: 20,
  },
  outlinedButtonSelected: {
    color: "black",
    backgroundColor: " white !important",
    border: "1px solid white",
    fontSize: 35,
    padding: 20,
  },
  whitebg: {
    background: "white",
  },
  hidden: {
    display: "none",
  },
});

//TODO Transfer to Utils
const URL_TIME_DATE_FORMAT = "YYYY-MM-DD-HHmm";

// fade out after 20s

class ControlPanel extends Component {
  constructor(props) {
    super();

    this.rootRef = React.createRef();
    this.wrapperRef = React.createRef();

    const { isSimulatedTime, simulatedTime } = props;
    this.state = {
      selectedDate: isSimulatedTime ? simulatedTime.startMs : new Date(),
      previewMode: isSimulatedTime ? "preview" : "live",
      fullScreen: false,
      activePanel: isSimulatedTime ? "preview_mode" : "live_mode",
    };
  }

  componentDidMount() {
    this.adjustContentToScreen();
    window.addEventListener("keyup", this.handleKeyboardResetImageControls);
  }

  componentDidUpdate() {
    this.adjustContentToScreen();
  }

  adjustContentToScreen = () => {
    let currentRoot = this.rootRef.current;
    let currentWrapper = this.wrapperRef.current;

    if (!currentRoot || !currentWrapper || !this.props.activeScreen)
      return false; // component hidden
    // console.log("adjustContentToScreen");

    const verticalPixelSz = 1920;
    const horizontalPixelSz = 1080;

    const { clientHeight, clientWidth } = currentRoot;

    let scale = clientHeight / verticalPixelSz;

    // Adjust for very narrow viewports
    let yPosition = 0;
    if (clientWidth < horizontalPixelSz * scale) {
      scale = clientWidth / horizontalPixelSz;
      const wrapperHeight = currentWrapper.getBoundingClientRect().height;
      yPosition = clientHeight / 2 - wrapperHeight / 2;
    }

    currentWrapper.style.cssText = ` 
    
        width: ${horizontalPixelSz}px;
        height: ${verticalPixelSz}px;
        position: absolute;
        transform: scale(${scale});
        transform-origin: left top;
        background: rgba(0, 0, 0, 0.7);

      `;

    const wrapperWidth = currentWrapper.getBoundingClientRect().width;

    const xPosition =
      clientWidth > wrapperWidth ? clientWidth / 2 - wrapperWidth / 2 : 0;
    // currentWrapper.style.left = `${xPosition}px`;
    currentWrapper.style.top = `${yPosition}px`;
  };

  handleDateChange = (momentDate) => {
    const {
      setSimulatedTime,
      accessCode,
      activeScreen: { id },
      mouseMoved,
      synchronizeContentNoApiCall,
    } = this.props;

    mouseMoved();

    this.setState({
      previewMode: "preview",
      selectedDate: momentDate.toDate(),
    });

    setSimulatedTime({ startMs: momentDate.valueOf() });
    const a = momentDate.valueOf();
    const b = momentDate.format(URL_TIME_DATE_FORMAT);

    synchronizeContentNoApiCall();
    history.push(`/${accessCode}/screen/${id}?simTime=${b}`);
  };

  handleModeToggle = (e, newMode) => {
    const {
      setSimulatedTime,
      clearSimulatedTime,
      accessCode,
      activeScreen: { id },
      mouseMoved,
      localTime,
    } = this.props;

    mouseMoved();

    if (newMode) this.setState({ previewMode: newMode });

    if (newMode === "live") {
      clearSimulatedTime();
      history.push(`/${accessCode}/screen/${id}`);
    } else if (newMode === "preview") {
      const URLformattedDateTime =
        moment(localTime).format(URL_TIME_DATE_FORMAT);

      setSimulatedTime({ startMs: localTime.valueOf() });
      history.push(
        `/${accessCode}/screen/${id}?simTime=${URLformattedDateTime}`
      );
    }
  };

  handleTimeChange = ({ timeAdjustment }) => {
    const {
      simulatedTime,
      setSimulatedTime,
      accessCode,
      synchronizeContentNoApiCall,
      activeScreen: { id },
      mouseMoved,
    } = this.props;
    const { previewMode } = this.state;

    mouseMoved();

    if (previewMode === "preview") {
      setSimulatedTime({ startMs: simulatedTime.startMs + timeAdjustment });
      this.setState({ selectedDate: simulatedTime.startMs + timeAdjustment });
      const a = new Date(simulatedTime.startMs + timeAdjustment);
      const b = moment(a).format(URL_TIME_DATE_FORMAT);

      synchronizeContentNoApiCall();

      history.push(`/${accessCode}/screen/${id}?simTime=${b}`);
    }
  };

  handleOpenScreenSelect = () => {
    const {
      setOpenScreenSelectModal,
      screensList,
      accessCode,
      screenMode,
      mouseMoved,
    } = this.props;
    if (!screensList) return false;

    mouseMoved();

    setOpenScreenSelectModal({
      headline: "Select Screen:",
      screensList,
      accessCode,
      screenMode,
    });
  };

  handlePanelSwitch = (panelName) => (e) => {
    window.removeEventListener("keyup", this.handleKeyboardImageControls);

    if (panelName === "live_mode") this.handleModeToggle(null, "live");

    if (panelName === "preview_mode") this.handleModeToggle(null, "preview");

    if (panelName === "image_controls")
      window.addEventListener("keyup", this.handleKeyboardImageControls);

    this.setState({ activePanel: panelName });
  };

  handleKeyboardImageControls = (e) => {
    switch (e.keyCode) {
      case 38: // up
        this.props.handleUp();
        break;
      case 40: // down
        this.props.handleDown();
        break;
      case 37: // left
        this.props.handleLeft();
        break;
      case 39: //right
        this.props.handleRight();
        break;
      case 74: // 'j'
        this.props.handleLeft(10);
        break;
      case 75: // 'k'
        this.props.handleScaleReset();
      case 76: // 'l'
        this.props.handleRight(10);
        break;
      case 73: // 'i'
        this.props.handleScaleDown();
        break;
      case 79: // 'o'
        this.props.handleScaleUp();
        break;
      default:
        break;
    }
  };

  handleKeyboardResetImageControls = (e) => {
    switch (e.keyCode) {
      case 75: // 'k'
      case 82: // 'r'
        this.props.handleScaleReset();

      default:
        break;
    }
  };

  render() {
    const {
      classes,
      show,
      simulatedTime,
      activeScreen: { name, type, id },
      screensList,
      accessCode,
      screenMode,
      availableContentItems,
      playlistContent,
      currentPlaylistIndex,
      currentViewContentData,
      localTime,
      playlistContentSplitScreenFirst,
      playlistContentSplitScreenSecond,
      currentPlaylistIndexSplitScreenFirst,
      currentPlaylistIndexSplitScreenSecond,
      handleScaleUp,
      handleScaleDown,
      handleScaleReset,
      handleUp,
      handleDown,
      handleLeft,
      handleRight,
      clearOfflineDatabaseAndReload,
      setSimulatedTime,
      setCurrentIndex,
      hideControlPanel,
      synchronizeContentNoApiCall,
      enabledSound,
      handleToggleSound,
    } = this.props;

    const { previewMode, activePanel } = this.state;

    if (!show) return false;

    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={classes.root} ref={this.rootRef}>
            <div
              ref={this.wrapperRef}
              style={{ transformOrigin: "left top", position: "absolute" }}
            >
              <Grid container alignContent="center" className={classes.wrapper}>
                <Grid
                  container
                  alignContent="center"
                  style={{
                    padding: 30,
                    borderBottom: "1px solid white",
                    background: "rgb(0,0,0,0.7)",
                  }}
                >
                  <Grid item xs={6}>
                    {" "}
                    {this.state.fullScreen === false && (
                      <Button
                        className={classes.outlinedButton}
                        style={{ fontSize: 30 }}
                        onClick={() => {
                          this.setState({ fullScreen: true });
                          launchIntoFullscreen(document.documentElement);
                        }}
                      >
                        Enter Full Screen &nbsp;&nbsp;
                        <FullscreenIcon fontSize="large" />
                      </Button>
                    )}
                    {this.state.fullScreen === true && (
                      <Button
                        className={classes.outlinedButton}
                        style={{ fontSize: 30 }}
                        onClick={() => {
                          this.setState({ fullScreen: false });
                          exitFullscreen();
                        }}
                      >
                        Exit FullScreen
                        <FullscreenExitIcon fontSize="large" />
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      className={classes.outlinedButton}
                      style={{ fontSize: 30 }}
                      onClick={() => {
                        setTimeout(() => {
                          // console.log("hiding control panel");
                          hideControlPanel();
                        }, 600);
                      }}
                    >
                      Close Control Panel
                      <CloseIcon fontSize="large" />
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  alignContent="center"
                  style={{
                    padding: 20,
                  }}
                >
                  <Grid item xs={12} style={{ fontSize: 40 }}>
                    {name}
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    {" "}
                    <Button
                      className={
                        activePanel === "select_screen"
                          ? classes.outlinedButtonSelected
                          : classes.outlinedButton
                      }
                      style={{ fontSize: 30, lineHeight: 1 }}
                      onClick={this.handlePanelSwitch("select_screen")}
                    >
                      Select Screen
                    </Button>
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 10 }}>
                  <Grid xs={9}></Grid>
                  <Grid item xs={3}>
                    {" "}
                    <Button
                      className={
                        enabledSound
                          ? classes.outlinedButtonSelected
                          : classes.outlinedButton
                      }
                      style={{ fontSize: 30, width: "100%", height: "99%" }}
                      onClick={handleToggleSound}
                    >
                      {enabledSound ? (
                        <VolumeUpIcon style={{ fontSize: 72 }} />
                      ) : (
                        <VolumeOffIcon style={{ fontSize: 72 }} />
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 10 }}>
                  <Grid item xs={3}>
                    {" "}
                    <Button
                      className={
                        previewMode === "live"
                          ? classes.outlinedButtonSelected
                          : classes.outlinedButton
                      }
                      style={{ fontSize: 30, width: "100%", height: "99%" }}
                      onClick={this.handlePanelSwitch("live_mode")}
                    >
                      Live Mode
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    {" "}
                    <Button
                      className={
                        activePanel === "preview_mode"
                          ? classes.outlinedButtonSelected
                          : classes.outlinedButton
                      }
                      style={{ fontSize: 30, width: "99%" }}
                      onClick={this.handlePanelSwitch("preview_mode")}
                    >
                      Preview Mode
                    </Button>
                  </Grid>

                  <Grid item xs={3}>
                    {" "}
                    <Button
                      className={
                        activePanel === "offline_content"
                          ? classes.outlinedButtonSelected
                          : classes.outlinedButton
                      }
                      style={{ fontSize: 30, width: "99%" }}
                      onClick={this.handlePanelSwitch("offline_content")}
                    >
                      Offline content
                    </Button>
                  </Grid>

                  <Grid item xs={3}>
                    {" "}
                    <Button
                      className={
                        activePanel === "image_controls"
                          ? classes.outlinedButtonSelected
                          : classes.outlinedButton
                      }
                      style={{ fontSize: 30, width: "99%" }}
                      onClick={this.handlePanelSwitch("image_controls")}
                    >
                      Image Controls
                    </Button>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    {activePanel === "offline_content" && (
                      <Fragment>
                        <Button
                          className={classes.outlinedButton}
                          style={{
                            marginTop: 20,
                            marginBottom: 40,
                            fontSize: 24,
                            padding: 10,
                          }}
                          onClick={clearOfflineDatabaseAndReload}
                        >
                          Clear downloaded content
                        </Button>

                        <AvailableContent
                          availableContentItems={availableContentItems}
                          playlistContent={playlistContent}
                          currentPlaylistIndex={currentPlaylistIndex}
                        />
                      </Fragment>
                    )}
                    {activePanel === "live_mode" && (
                      <Fragment>
                        <Grid item xs={12} style={{ marginBottom: 30 }}>
                          {" "}
                          {simulatedTime && simulatedTime.diffMs
                            ? formatReadable(
                                new Date(localTime + simulatedTime.diffMs)
                              )
                            : formatReadable(new Date(localTime))}
                        </Grid>
                        <UpcomingContent
                          availableContentItems={availableContentItems}
                          playlistContent={playlistContent}
                          currentPlaylistIndex={currentPlaylistIndex}
                          currentViewContentData={currentViewContentData}
                          localTime={localTime}
                          simulatedTime={simulatedTime}
                          playlistContentSplitScreenFirst={
                            playlistContentSplitScreenFirst
                          }
                          playlistContentSplitScreenSecond={
                            playlistContentSplitScreenSecond
                          }
                          currentPlaylistIndexSplitScreenFirst={
                            currentPlaylistIndexSplitScreenFirst
                          }
                          currentPlaylistIndexSplitScreenSecond={
                            currentPlaylistIndexSplitScreenSecond
                          }
                          screenType={type}
                          accessCode={accessCode}
                          activeScreenId={id}
                          setSimulatedTime={setSimulatedTime}
                          setCurrentIndex={setCurrentIndex}
                        />
                      </Fragment>
                    )}
                    {activePanel === "select_screen" && (
                      <ScreenSelect
                        screensList={screensList}
                        accessCode={accessCode}
                        screenMode={screenMode}
                      />
                    )}
                    {activePanel === "image_controls" && (
                      <ImageControls
                        handleScaleUp={handleScaleUp}
                        handleScaleDown={handleScaleDown}
                        handleScaleReset={handleScaleReset}
                        handleUp={handleUp}
                        handleDown={handleDown}
                        handleLeft={handleLeft}
                        handleRight={handleRight}
                      />
                    )}
                  </Grid>
                </Grid>
                {activePanel === "preview_mode" && (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ padding: 10, marginTop: 10, marginBottom: 10 }}
                    >
                      {" "}
                      <span
                        style={{
                          paddingLeft: 10,
                          paddingRight: 10,
                          fontSize: 50,
                        }}
                      >
                        Show content scheduled for:
                      </span>
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: 10 }}>
                      {" "}
                      <Button
                        className={classes.outlinedButton}
                        onClick={(e) => {
                          this.handleTimeChange({
                            timeAdjustment: -3600 * 24 * 1000,
                          });
                        }}
                      >
                        Prev. Day
                      </Button>{" "}
                    </Grid>
                    <Grid item xs={6} style={{ marginBottom: 10 }}>
                      {" "}
                      <DateTimePicker
                        inputVariant="outlined"
                        format={"MMM D, HH:mm:ss.SSS"}
                        InputProps={{ className: classes.textField }}
                        value={
                          simulatedTime && simulatedTime.diffMs
                            ? new Date(localTime + simulatedTime.diffMs)
                            : new Date(localTime)
                        }
                        onChange={this.handleDateChange}
                      />
                    </Grid>

                    <Grid item xs={3} style={{ marginBottom: 10 }}>
                      {" "}
                      <Button
                        className={classes.outlinedButton}
                        onClick={(e) => {
                          this.handleTimeChange({
                            timeAdjustment: 3600 * 24 * 1000,
                          });
                        }}
                      >
                        Next Day
                      </Button>{" "}
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: 20 }}>
                      {" "}
                      <Button
                        className={classes.outlinedButton}
                        onClick={(e) => {
                          this.handleTimeChange({
                            timeAdjustment: -6 * 3600 * 1000,
                          });
                        }}
                      >
                        - 6 hours
                      </Button>{" "}
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: 50 }}>
                      {" "}
                      <Button
                        className={classes.outlinedButton}
                        onClick={(e) => {
                          this.handleTimeChange({
                            timeAdjustment: -3600 * 1000,
                          });
                        }}
                      >
                        - 1 hour
                      </Button>{" "}
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: 50 }}>
                      {" "}
                      <Button
                        className={classes.outlinedButton}
                        onClick={(e) => {
                          this.handleTimeChange({
                            timeAdjustment: 3600 * 1000,
                          });
                        }}
                      >
                        + 1 hour
                      </Button>{" "}
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: 50 }}>
                      {" "}
                      <Button
                        className={classes.outlinedButton}
                        onClick={(e) => {
                          this.handleTimeChange({
                            timeAdjustment: 6 * 3600 * 1000,
                          });
                        }}
                      >
                        + 6 hours
                      </Button>{" "}
                    </Grid>
                    <UpcomingContent
                      availableContentItems={availableContentItems}
                      playlistContent={playlistContent}
                      currentPlaylistIndex={currentPlaylistIndex}
                      currentViewContentData={currentViewContentData}
                      localTime={localTime}
                      simulatedTime={simulatedTime}
                      playlistContentSplitScreenFirst={
                        playlistContentSplitScreenFirst
                      }
                      playlistContentSplitScreenSecond={
                        playlistContentSplitScreenSecond
                      }
                      currentPlaylistIndexSplitScreenFirst={
                        currentPlaylistIndexSplitScreenFirst
                      }
                      currentPlaylistIndexSplitScreenSecond={
                        currentPlaylistIndexSplitScreenSecond
                      }
                      screenType={type}
                      accessCode={accessCode}
                      activeScreenId={id}
                      setSimulatedTime={setSimulatedTime}
                      setCurrentIndex={setCurrentIndex}
                      clickableItems={true}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    localTime: state.view.localTime,
    simulatedTime: state.app.simulatedTime,
    currentPlaylistIndexSplitScreenFirst: state.view.splitIndexFirst,
    currentPlaylistIndexSplitScreenSecond: state.view.splitIndexSecond,
  };
};

const styledControlPanel = withStyles(styles)(ControlPanel);
export default connect(mapStateToProps)(styledControlPanel);
