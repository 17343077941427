import { constants as types } from './types';

const initialState = {
  programmes: ['eins'],
};

const actionsMap = {
  [types.FETCH_USER_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      programmes: payload.programmes,
    };
  },
};

export default function app(state = initialState, action) {
  const reduceFn = actionsMap[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
