import { SyncDate, formatReadable } from "./time";

export function evaluateOfStartContent(
  offset,
  timezoneOffset,
  playlist,
  simulatedTime,
  serverMidnight
) {
  const dayMs = 86400000;
  const { diffMs, startMs } = simulatedTime;
  const timeSinceMidnight = diffMs
    ? (SyncDate.getTimeFromServerMidnight(serverMidnight) + diffMs) % dayMs
    : SyncDate.getTimeFromServerMidnight(serverMidnight);
  const fullPlaybackLengthMs = playlist.reduce(
    (acc, cur) => acc + cur.duration,
    0
  );

  let startFromSecondMs = timeSinceMidnight % fullPlaybackLengthMs;
  let countIndex = 0;

  // console.log({ startFromSecondMs, diffMs });

  if (startFromSecondMs >= 0) {
    while (startFromSecondMs >= playlist[countIndex].duration) {
      startFromSecondMs -= playlist[countIndex].duration;
      countIndex += 1;
    }
    // When simulated time is in the past
  } else if (diffMs && startFromSecondMs < 0) {
    startFromSecondMs = startFromSecondMs + dayMs;
    while (startFromSecondMs >= playlist[countIndex].duration) {
      startFromSecondMs -= playlist[countIndex].duration;
      countIndex += 1;
    }
  }
  if (!playlist[countIndex]) {
    console.error("playlist[countIndex] doesn't exist!");
  }

  return {
    countIndex,
    remainder: startFromSecondMs,
    content: {
      ...playlist[countIndex],
      duration: playlist[countIndex].duration - startFromSecondMs,
    },
  };
}
