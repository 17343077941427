import "./app.global.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchScreensData,
  setSimulatedTime,
  clearSimulatedTime,
  setOpenScreenSelectModal,
  closeModal,
  pingMonitoringService,
} from "../redux/actions";
import {
  setActiveScreen,
  setLocalTime,
  setCurrentIndex,
  setCurrentSyncedContent,
  setCurrentViewContent,
  synchronizeContentNoApiCall,
  updateRestructuredPlaylists,
  setViewportTransform,
  viewportResetScale,
} from "../redux/actions/view";
import {
  queueItemsToDownload,
  updateContentCache,
  saveOrUpdateResourcesMetadataToOfflineDB,
} from "../redux/actions/resources";
import { getRestucturedPlaylistContent } from "../redux/selectors/view";
import { getSimulatedTime, getIsSimulatedTime } from "../redux/selectors/app";

import { getScreensList } from "../redux/selectors/screens";

import View from "../components/View";

const mapStateToProps = (state) => {
  const restructuredPlaylists = state.view.restructuredPlaylistContent;

  const playlistContent = restructuredPlaylists ? restructuredPlaylists[0] : [];

  const playlistContentSplitScreenFirst = restructuredPlaylists
    ? restructuredPlaylists[0]
    : [];
  const playlistContentSplitScreenSecond = restructuredPlaylists
    ? restructuredPlaylists[1]
    : [];

  return {
    activeScreen: state.view.activeScreen,
    localTime: state.view.localTime,
    currentPlaylistIndex: state.view.currentIndex,
    splitCurrentIndexFirst: state.view.splitIndexFirst,
    splitCurrentIndexSecond: state.view.splitIndexSecond,
    currentContent: state.view.currentContent,
    playlistContent,
    playlistContentSplitScreenFirst,
    playlistContentSplitScreenSecond,
    currentViewContent: state.view.currentViewContent || "",
    simulatedTime: getSimulatedTime(state),
    isSimulatedTime: getIsSimulatedTime(state),
    screensList: getScreensList(state),
    viewportTransform: state.view.viewportTransform,
    currentViewContentData: state.view.currentViewContentData,
    availableContentItems: state.resources.availableContentItems,
    refreshIndex: state.resources.refreshIndex,
    extractedHtmlImages: state.resources.extractedHtmlImages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchScreensData,
      pingMonitoringService,

      saveOrUpdateResourcesMetadataToOfflineDB,
      queueItemsToDownload,
      updateContentCache,
      setActiveScreen,
      setSimulatedTime,
      clearSimulatedTime,
      setLocalTime,
      synchronizeContentNoApiCall,
      updateRestructuredPlaylists,
      setCurrentIndex,
      setCurrentSyncedContent,
      setCurrentViewContent,
      setOpenScreenSelectModal,
      closeModal,
      setViewportTransform,
      viewportResetScale,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
