import React, { Fragment, useRef } from "react";

import _, { filter } from "lodash";

import Grid from "@material-ui/core/Grid";

import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";

import WebIcon from "@material-ui/icons/Web";
import ImageIcon from "@material-ui/icons/Image";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";

//TODO Transfer to Utils
const URL_TIME_DATE_FORMAT = "YYYY-MM-DD-HHmm";

function UpcomingContent({
  playlistContent,
  currentPlaylistIndex,
  playlistContentSplitScreenFirst,
  playlistContentSplitScreenSecond,
  currentPlaylistIndexSplitScreenFirst,
  currentPlaylistIndexSplitScreenSecond,
  screenType,
  setSimulatedTime,
  setCurrentIndex,
  clickableItems,
}) {
  const listElementRef = useRef();
  // console.log(playlistContent[currentPlaylistIndex]);
  const renderPlaylist = ({
    upcomingItems,
    marginTop = 10,
    fontSize = 40,
    height = 1000,
    title = "Upcoming Content",
    currentIndexType,
    currentPlaylistIndex,
  }) => {
    if (!upcomingItems || upcomingItems.length === 0) return;

    return (
      <Fragment>
        <Grid
          item
          xs={12}
          style={{
            marginTop,
          }}
        >
          {title}
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={0}
          style={{
            marginTop: 20,
            fontSize,
            height,
            overflowY: "scroll",
            textAlign: "left",
          }}
          ref={listElementRef}
        >
          {upcomingItems.map((item, index) => {
            const moveClockToTime = item.timeStartWithOffset - 1000;

            // if (index < 2) {
            //   console.log({ item });
            // }

            return (
              <Grid
                container
                key={index}
                style={{
                  border:
                    currentPlaylistIndex === item.order
                      ? "2px solid white"
                      : "",
                  cursor: clickableItems ? "pointer" : "inherit",
                }}
                onClick={() => {
                  clickableItems &&
                    setSimulatedTime({ startMs: moveClockToTime });
                  clickableItems &&
                    setCurrentIndex(item.order, currentIndexType);
                  listElementRef.current.scrollTop = 0;
                }}
              >
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  {" "}
                  {renderItemTimings({ item })}
                </Grid>

                <Grid item xs={1}>
                  {" "}
                  {renderItemType({ type: item.type })}
                </Grid>
                <Grid item xs={9}>
                  {item.name}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Fragment>
    );
  };

  const renderItemType = ({ type }) => {
    switch (type) {
      case "html":
        return (
          <span style={{ color: "#20639B" }}>
            <WebIcon fontSize="large" />
          </span>
        );
      case "video":
        return (
          <span style={{ color: "#3CAEA3" }}>
            <OndemandVideoIcon fontSize="large" />
          </span>
        );
      case "image":
        return (
          <span style={{ color: "#173F5F" }}>
            <ImageIcon fontSize="large" />
          </span>
        );
      case "programme":
        return (
          <span style={{ color: "white" }}>
            <FormatListBulletedIcon fontSize="large" />
          </span>
        );
      default:
        return "";
    }
  };

  const renderItemTimings = ({ item }) => {
    return (
      <span>
        {item.timeStartWithOffset.format("HH:mm:ss")}&nbsp;
        <span style={{ fontSize: 10 }}>{item.order}&nbsp;</span>
      </span>
    );
  };

  const getUpcomingItems = ({
    playlistContent,
    currentPlaylistIndex,
    howMany = 50,
  }) => {
    return _.slice(
      playlistContent,
      currentPlaylistIndex - 5,
      currentPlaylistIndex + howMany
    ).filter((item) => item);
  };

  let upcomingItems = [];
  let upcomingItemsTop = [];
  let upcomingItemsBottom = [];

  if (screenType === "split") {
    upcomingItemsTop = getUpcomingItems({
      playlistContent: playlistContentSplitScreenFirst,
      currentPlaylistIndex: currentPlaylistIndexSplitScreenFirst,
      howMany: 300,
    });

    upcomingItemsBottom = getUpcomingItems({
      playlistContent: playlistContentSplitScreenSecond,
      currentPlaylistIndex: currentPlaylistIndexSplitScreenSecond,
      howMany: 100,
    });
  } else {
    upcomingItems = getUpcomingItems({
      playlistContent,
      currentPlaylistIndex,
      howMany: 300,
    });
  }

  const topPlaylist = React.useMemo(
    () =>
      renderPlaylist({
        upcomingItems: upcomingItemsTop,
        height: 480,
        title: "Top Playlist",
        fontSize: 35,
        currentIndexType: "splitFirst",
        currentPlaylistIndex: currentPlaylistIndexSplitScreenFirst,
      }),
    [upcomingItemsTop, currentPlaylistIndexSplitScreenFirst]
  );

  const bottomPlaylist = React.useMemo(
    () =>
      renderPlaylist({
        upcomingItems: upcomingItemsBottom,
        height: 480,
        title: "Bottom Playlist",
        marginTop: 30,
        fontSize: 35,
        currentIndexType: "splitSecond",
        currentPlaylistIndex: currentPlaylistIndexSplitScreenSecond,
      }),
    [upcomingItemsBottom, currentPlaylistIndexSplitScreenSecond]
  );

  const defaultPlaylist = React.useMemo(
    () =>
      renderPlaylist({
        upcomingItems,
        height: 1100,
        fontSize: 35,
        currentIndexType: "default",
        currentPlaylistIndex,
      }),
    [upcomingItems]
  );

  return (
    <Fragment>
      {topPlaylist}
      {bottomPlaylist}
      {defaultPlaylist}
    </Fragment>
  );
}

export default UpcomingContent;
