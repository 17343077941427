import { constants as types } from "../reducers/types";
import _ from "lodash";
import path from "path";
import { getExtensionOfFile } from "../../utils/get-file-name-from-url";
import htmlFromUrl from "../../utils/html-from-url";

import * as Sentry from "@sentry/react";

const parser = new DOMParser();

export const injectBinaryImgBlobIntoHtml = ({ htmlString, binaryImages }) => {
  const htmlElement = parser.parseFromString(htmlString, "text/html");
  const body = htmlElement.querySelector("body");

  // console.log({ binaryImages });

  if (!binaryImages || binaryImages.length === 0) return { html: htmlString };

  body.querySelectorAll("img").forEach((img) => {
    const foundImg = binaryImages.find((el) => img.src === el.content);
    //TODO extract into function

    // console.log({ foundImg, src: img.src, binaryImages });
    if (foundImg) {
      const extension = path.extname(img.src).toLowerCase();

      const blobType = `image/${extension.substr(1)}`;

      // We have to convert the buffer to a blob:
      let blob = new Blob([new Uint8Array(foundImg.data)], {
        type: blobType,
      });

      // The blob gives us a URL to the video file:
      let url = window.URL.createObjectURL(blob);
      img.style.border = "1px solid lightgrey";
      img.src = url;
    }
    // console.log({ foundImg, imgsrc: img.src });
  });

  return { html: body.innerHTML };
};

export const saveBinaryData = ({ db, content, data }) => {
  return db.binaryStore
    .put({
      data,
      content,
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const queueItemsToDownload =
  ({ db }) =>
  async (dispatch, getState) => {
    if (!db) {
      console.error("No offline database found!");
      return false;
    }

    const {
      resources: { downloadIndex },
    } = getState();

    let dbItemCount = 0;

    try {
      dbItemCount = await db.fileStore.count();
    } catch (error) {
      console.warn("Offline DB error trying to cache content");
      return false;
    }

    if (!dbItemCount) {
      console.log("db item count 0 in queueItemsToDownload");
      return false;
    }

    let itemsInProgressCount = 0;

    try {
      itemsInProgressCount = await db.fileStore
        .where("downloadStatus")
        .equals(1)
        .count();
    } catch (error) {
      console.warn(itemsInProgressCount);
      return false;
    }

    if (itemsInProgressCount > 10) {
      console.log(`Download rate limit, extra downloads not added`, {
        itemsInProgressCount,
      });
      return;
    }

    // const ITEM_REFRESH_RATE = 25;

    // const offsetItemsBy =
    //   Math.floor(
    //     ((downloadIndex * ITEM_REFRESH_RATE) % dbItemCount) / ITEM_REFRESH_RATE
    //   ) * ITEM_REFRESH_RATE;

    const storedItemsChunk = await db.fileStore
      .where("type")
      .equalsIgnoreCase("html")
      .or("type")
      .equalsIgnoreCase("video")
      .or("type")
      .equalsIgnoreCase("image")
      // .offset(offsetItemsBy)
      // .limit(ITEM_REFRESH_RATE)
      .toArray();

    dispatch({
      type: types.INCREMENT_RESOURCE_DOWNLOAD_INDEX,
    });

    // console.log("Tracked offline items: " + storedItemsChunk.length);

    if (storedItemsChunk && storedItemsChunk.length > 0) {
      Promise.all(
        storedItemsChunk.map((item) =>
          downloadAndSaveFile({ db, item, dispatch })
        )
      )
        .then((values) => {
          // console.log({ values });
          dispatch({
            type: types.SET_AVAILABLE_CONTENT_ITEMS,
            payload: values
              // .filter((i) => i.fileName)
              .map((i) => {
                return {
                  fileName: i.fileName,
                  downloadStatus: i.downloadStatus,
                  updated: i.updated,
                  content: i.content,
                  name: i.name,
                  type: i.type,
                };
              }),
          });
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  };

export const downloadAndSaveFile = async ({
  db,
  item: { name, fileName, content, type },
  dispatch,
}) => {
  if (!db) return false;

  let dbItem = null;

  try {
    dbItem = await db.fileStore.get({ content });
  } catch (error) {
    console.warn("downloadAndSave -> db not available, returning", { error });
  }

  // console.log("about to download item:", name);

  // if html, need to process is each time, in case images change
  if (type === "html") {
    const { htmlString, extractedImageUrls } = await htmlFromUrl({
      url: content,
    });

    // const extractedBinaryImages = await db.binaryStore
    //   .where("content")
    //   .anyOf(extractedImageUrls)
    //   .toArray()
    //   .catch((error) => console.warn(error));

    // const { html } = injectBinaryImgBlobIntoHtml({
    //   htmlString,
    //   binaryImages: extractedBinaryImages,
    // });

    dispatch({
      type: types.SET_EXTRACTED_HTML_IMAGES,
      payload: extractedImageUrls,
    });

    return new Promise(function (resolve, reject) {
      saveBinaryData({
        db,
        content,
        data: htmlString,
      })
        .then(() =>
          db.fileStore
            .update(content, {
              downloadStatus: 2,
            })
            .catch((e) => {
              reject(`failed to update status of html file ${dbItem.fileName}`);
            })
            .finally(() => {
              resolve({
                name,
                fileName,
                content,
                type,
                downloadStatus: dbItem.downloadStatus,
                updated: new Date(),
              });
            })
        )
        .catch((e) => {
          console.warn(e);
          reject(`failed to download html file ${dbItem.fileName}`);
        });
    });
  } else if (dbItem.downloadStatus === 2 || dbItem.downloadStatus === 1) {
    return {
      name,
      fileName,
      content,
      type,
      downloadStatus: dbItem.downloadStatus,
      updated: new Date(),
    };
    // -1 means failed, so re-trying
  } else if (dbItem.downloadStatus === 0 || dbItem.downloadStatus === -1) {
    if (dbItem.downloadStatus === -1) {
      // console.log("Retrying download of: ", dbItem.content);
    }

    if (type === "video" || type === "image") {
      try {
        await db.fileStore.update(dbItem.content, {
          downloadStatus: 1,
          updated: new Date(),
        });
      } catch (error) {
        console.warn(error);
        return false;
      }

      return new Promise(function (resolve, reject) {
        downloadFileToDataURL({
          url: dbItem.content,
        })
          .then((fileData) => {
            if (fileData === "" || !fileData) {
              // console.error("empty fileData", { fileData });
              db.fileStore.update(dbItem.content, {
                downloadStatus: -1,
                updated: new Date(),
              });
              resolve({
                name,
                fileName,
                content,
                type,
                downloadStatus: -1,
                updated: new Date(),
              });
            } else {
              saveBinaryData({
                db,
                content: dbItem.content,
                data: fileData,
              })
                .catch((e) => {
                  console.warn(
                    e,
                    `failed to save binary file ${dbItem.fileName}`
                  );

                  resolve({
                    name,
                    fileName,
                    content,
                    type,
                    downloadStatus: -1,
                    updated: new Date(),
                  });
                })
                .then(() =>
                  db.fileStore
                    .update(dbItem.content, {
                      downloadStatus: 2,
                      updated: new Date(),
                    })
                    .then(() => {
                      resolve({
                        name,
                        fileName,
                        content,
                        type,
                        downloadStatus: 2,
                        updated: new Date(),
                      });
                    })
                    .catch((e) => {
                      reject(`failed to update file ${dbItem.fileName}`);
                    })
                );
            }
          })
          .catch((e) => {
            console.warn({
              msg: "failed to download file",
              fileName: dbItem.fileName,
              e,
            });
            db.fileStore
              .update(dbItem.content, {
                downloadStatus: -1,
                updated: new Date(),
              })
              .catch((error) => console.warn(error));

            resolve({
              name,
              fileName,
              content,
              type,
              downloadStatus: -1,
              updated: new Date(),
            });
          });
      });
    }
  }
};

let downloadsInProgress = 0;

export const downloadFileToDataURL = async ({ url, skip_url_cache }) => {
  try {
    // console.log({ downloadsInProgress });

    if (downloadsInProgress > 5) return null;
    downloadsInProgress += 1;
    const fullURL = skip_url_cache
      ? url
      : `${url}?cache=${Math.floor(Math.random() * 10000) + 0}`;
    const res = await fetch(fullURL);
    const blob = await res.blob();
    downloadsInProgress -= 1;
    return fileToDataURL(blob);
  } catch (error) {
    console.error("downloadFileToDataURL", { error, url });
    downloadsInProgress -= 1;
  }
  return "";
};

export const fileToDataURL = (blob) => {
  return new Promise(function (resolve, reject) {
    var reader = new FileReader();
    function handleEvent(event) {
      // console.log(`${event.type}: ${event.loaded} bytes transferred`);

      if (event.type === "load") {
        // console.log({ readerresult: reader.result, event });
        resolve(reader.result);
      }
    }

    reader.onerror = function (event) {
      reject("FileReader error");
    };
    // reader.addEventListener("loadstart", handleEvent);
    reader.addEventListener("load", handleEvent);
    // reader.addEventListener("loadend", handleEvent);
    // reader.addEventListener("progress", handleEvent);
    // reader.addEventListener("abort", handleEvent);
    reader.readAsArrayBuffer(blob);
  });
};

export const saveOrUpdateResourcesMetadataToOfflineDB =
  ({ db }) =>
  async (dispatch, getState) => {
    const {
      resources: { contentItems, extractedHtmlImages },
    } = getState();

    if (!db) return false;

    const itemCount = await db.fileStore.count().catch((err) => {
      console.warn(err);
      return false;
    });

    const filteredHtmlMessages = contentItems.filter(
      (item) => item.type === "html"
    );

    // first, extract images from html content, images may have been added
    await Promise.all(
      filteredHtmlMessages.map(async ({ content }) => {
        const { extractedImageUrls } = await htmlFromUrl({
          url: content,
        });
        // console.log({ url: content, extractedImages });
        //TODO this might not work
        dispatch({
          type: types.SET_EXTRACTED_HTML_IMAGES,
          payload: extractedImageUrls,
        });
      })
    );

    const contentitemsWithExtractedImages = _.concat(
      contentItems,
      extractedHtmlImages.map((url) => ({
        content: url,
        type: "image",
        name: `HTML IMG ${url.substr(url.length - 30)}`,
        fileName: `HTML IMG ${url.substr(url.length - 30)}`,
      }))
    );

    // console.log({ extractedHtmlImages, contentitemsWithExtractedImages });

    if (itemCount === 0) {
      console.log("No stored items yet -> bulkPut", {
        contentitemsWithExtractedImages,
      });
      // add extra params
      return db.fileStore
        .bulkPut(
          contentitemsWithExtractedImages.map((item) => ({
            ...item,

            downloadStatus: 0,
            created: new Date(),
            updated: new Date(),
          }))
        )
        .catch((e) => {
          console.error(e);
        });
    } else {
      return Promise.all(
        _.chunk(contentitemsWithExtractedImages, 25).map((chunk) => {
          // console.log({ chunk });
          return new Promise(function (resolve, reject) {
            Promise.all(
              chunk.map((item) => {
                // console.log({ item });
                return db.fileStore
                  .get({ content: item.content }, (existingItem) => {
                    // console.log({ existingItem });
                    return db.fileStore
                      .update(existingItem.content, {
                        updated: new Date(),
                      })
                      .catch((err) => {
                        reject(
                          `updating item but error caught ${item.content}`
                        );
                      });
                  })
                  .catch((err) => {
                    // if doesn't exist already -> add
                    return db.fileStore
                      .add({
                        ...item,
                        downloadStatus: 0,
                        created: new Date(),
                        updated: new Date(),
                      })
                      .catch((err) => {
                        reject(
                          `adding new item but error caught ${item.content}`
                        );
                      });
                  });
              })
            ).then(() => {
              resolve();
            });
          });
        })
      ).catch((errors) => console.log(errors));
    }
  };

const cacheFromLocalDB = async ({
  db,
  itemsToCache,
  extractedHtmlImages,
  dispatch,
}) => {
  let itemsWithDBItem = [];

  const extractedBinaryImages = await db.binaryStore
    .where("content")
    .anyOf(extractedHtmlImages)
    .toArray()
    .catch((error) => console.warn(error));

  try {
    itemsWithDBItem = await Promise.all(
      itemsToCache.map((itemToCache) =>
        db.binaryStore
          .get({ content: itemToCache.content }, function (bi) {
            // console.log("binaryStore item: ", { bi, content: itemToCache.content });
            if (!bi) {
              console.log("binaryStore item missing: ", {
                bi,
                content: itemToCache.content,
              });
              return getBinaryFileFromURLForCache({ itemToCache });
              // return { content: itemToCache.content, data: null };
            }

            const { content, data } = bi;

            // if html, this is (anything but) straightforward
            if (itemToCache.type === "html") {
              const { html } = injectBinaryImgBlobIntoHtml({
                htmlString: data,
                binaryImages: extractedBinaryImages,
              });
              // console.log("storing modified html in cache:", html);
              return { content, data: html };
            }

            const extension = path.extname(content).toLowerCase();

            const blobType = `${itemToCache.type}/${extension.substr(1)}`;

            // We have to convert the buffer to a blob:
            let blob = new Blob([new Uint8Array(data)], {
              type: blobType,
            });

            let url = window.URL.createObjectURL(blob);

            // video.src = url;
            return { content, objectURL: url };
          })
          .catch((err) => {
            // console.log("binaryStore item: ", { bi, content: itemToCache.content });
            console.error({
              msg: "binaryStore get failed",
              err,
              itemToCache,
            });
          })
      )
    );
  } catch (err) {
    console.error(err);
  }

  // console.log(global.fileCache);

  return itemsWithDBItem;

  // dispatch({
  //   type: types.SET_CURRENT_VIEW_CONTENT_DATA,
  //   payload: itemsWithDBItem,
  // });
};

const getBinaryFileFromURLForCache = async ({ itemToCache }) => {
  return downloadFileToDataURL({
    url: itemToCache.content,
    skip_url_cache: true,
  })
    .then((fileData) => {
      if (fileData === "" || !fileData) {
        return { content: itemToCache.content, data: null };
      } else {
        // if html, this is straightforward
        if (itemToCache.type === "html") {
          return { content: itemToCache.content, data: null };
        }

        const extension = path.extname(itemToCache.content).toLowerCase();

        const blobType = `${itemToCache.type}/${extension.substr(1)}`;

        // We have to convert the buffer to a blob:
        let blob = new Blob([new Uint8Array(fileData)], {
          type: blobType,
        });

        let url = window.URL.createObjectURL(blob);

        // video.src = url;
        return { content: itemToCache.content, objectURL: url };
      }
    })
    .catch((err) => {
      console.error({
        err,
        itemToCache,
      });
    });
};

const cacheFromURL = async ({ itemsToCache, dispatch }) => {
  let itemsWithBlob = [];

  try {
    itemsWithBlob = await Promise.all(
      itemsToCache.map((itemToCache) =>
        getBinaryFileFromURLForCache({ itemToCache })
      )
    );
  } catch (err) {
    console.error(err);
  }

  return itemsWithBlob;
};

export const updateContentCache =
  ({ db, props }) =>
  async (dispatch, getState) => {
    const CACHE_SIZE = 2;
    const {
      activeScreen,
      playlistContent,
      splitCurrentIndexFirst,
      splitCurrentIndexSecond,
      playlistContentSplitScreenSecond,
      currentPlaylistIndex,
      extractedHtmlImages,
    } = props;

    if (!activeScreen) return false;

    const { type } = activeScreen;

    let startIndex = currentPlaylistIndex;
    if (type === "split") startIndex = splitCurrentIndexFirst;
    let endIndex = startIndex + CACHE_SIZE; // in case other items like programme are coming up

    if (startIndex < 0) {
      startIndex = 0;
      endIndex = CACHE_SIZE;
    }

    const upcomingItemsInsideMainPlaylist = _.slice(
      playlistContent.filter(
        (item) =>
          item.type === "html" || item.type === "image" || item.type === "video"
      ),
      startIndex,
      endIndex
    );

    let upcomingItemsInsideSecondPlaylist = [];

    // add items from lower half playlist. Top half is the same as playlistContent
    if (type === "split") {
      startIndex = splitCurrentIndexSecond - 1;
      endIndex = startIndex + CACHE_SIZE;

      if (splitCurrentIndexSecond < CACHE_SIZE) {
        startIndex = 0;
        endIndex = CACHE_SIZE;
      }

      upcomingItemsInsideSecondPlaylist = _.slice(
        playlistContentSplitScreenSecond,
        startIndex,
        endIndex
      ).filter(
        (item) =>
          item.type === "html" || item.type === "image" || item.type === "video"
      );
    }

    const currentCacheItemsMainPlaylist = global.fileCache.mainPlaylistCache
      .filter((i) => i && i.content)
      .map((i) => i.content);

    const currentCacheItemsSecondaryPlaylist =
      global.fileCache.secondaryPlaylistCache
        .filter((i) => i && i.content)
        .map((i) => i.content);

    const itemsToCacheMainPlaylist = _.slice(
      upcomingItemsInsideMainPlaylist,
      0,
      CACHE_SIZE
    );
    const itemsToCacheSecondaryPlaylist = _.slice(
      upcomingItemsInsideSecondPlaylist,
      0,
      CACHE_SIZE
    );

    const uniqueItemsMainPlaylist = itemsToCacheMainPlaylist.filter(
      (item) => !currentCacheItemsMainPlaylist.includes(item.content)
    );
    const uniqueItemsSecondaryPlaylist = itemsToCacheSecondaryPlaylist.filter(
      (item) => !currentCacheItemsSecondaryPlaylist.includes(item.content)
    );

    let itemsWithBinaryDataMainPlaylist = [];
    let itemsWithBinaryDataSecondaryPlaylist = [];

    if (db) {
      itemsWithBinaryDataMainPlaylist = await cacheFromLocalDB({
        db,
        itemsToCache: uniqueItemsMainPlaylist,
        extractedHtmlImages,
        dispatch,
      });
      itemsWithBinaryDataSecondaryPlaylist = await cacheFromLocalDB({
        db,
        itemsToCache: uniqueItemsSecondaryPlaylist,
        extractedHtmlImages,
        dispatch,
      });
    } else {
      itemsWithBinaryDataMainPlaylist = await cacheFromURL({
        itemsToCache: uniqueItemsMainPlaylist,
        dispatch,
      });
      itemsWithBinaryDataSecondaryPlaylist = await cacheFromURL({
        itemsToCache: uniqueItemsSecondaryPlaylist,
        dispatch,
      });
    }

    global.fileCache.mainPlaylistCache.push(...itemsWithBinaryDataMainPlaylist);
    global.fileCache.secondaryPlaylistCache.push(
      ...itemsWithBinaryDataSecondaryPlaylist
    );

    const lastThreeMainPlaylist = global.fileCache.mainPlaylistCache.slice(-3);
    const toRemoveMainPlaylist =
      global.fileCache.mainPlaylistCache.length > 3
        ? global.fileCache.mainPlaylistCache.slice(0, -3)
        : [];

    const lastThreeSecondaryPlaylist =
      global.fileCache.secondaryPlaylistCache.slice(-3);
    const toRemoveSecondaryPlaylist =
      global.fileCache.secondaryPlaylistCache.length > 3
        ? global.fileCache.secondaryPlaylistCache.slice(0, -3)
        : [];

    global.fileCache.mainPlaylistCache = lastThreeMainPlaylist;
    global.fileCache.secondaryPlaylistCache = lastThreeSecondaryPlaylist;

    toRemoveMainPlaylist.map((i) => {
      // console.log("Removing.. " + i.content);
      i && i.objectURL && URL.revokeObjectURL(i.objectURL);
      return true;
    });

    toRemoveSecondaryPlaylist.map((i) => {
      // console.log("Removing.. " + i.content);
      i && i.objectURL && URL.revokeObjectURL(i.objectURL);
      return true;
    });
  };
