import styled from "styled-components";

const StyledHeaderTitle = styled.div`
  font-family: ${(props) => props.fontFamily};
  font-weight: 700;
  font-size: ${(props) => (props.fontSize ? props.fontSize + "px" : "1em")};
  color: ${(props) => props.colorText};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  text-align: start;
  padding-left: 0px;
  padding-bottom: 4px;
`;

const StyledProgrammeHeader = styled.div`
  color: ${(props) => props.colorText};
  background-color: ${(props) => props.backgroundColor};
  display: grid;
  grid-template-columns: ${(props) => props.columnDistribution};
  align-content: center;
  align-items: center;
`;

export { StyledHeaderTitle, StyledProgrammeHeader };
