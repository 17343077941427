import _ from 'lodash';
import { constants as types } from './types';

const initialState = {
  screensList: null,
  contentGroup: [],
};

const actionMap = {
  [types.FETCH_SCREENS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      screensList: payload,
    };
  },
  [types.FETCH_USER_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      contentGroup: payload.contentGroup,
    };
  },
};

export default function screensReducer(state = initialState, action) {
  const reduceFn = actionMap[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
