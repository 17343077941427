import styled from "styled-components";

export const StyledSessionItem = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


  animation: fadeIn 0.3s ease-in;
  &.programmeSection {
    display: grid;
    grid-template-columns: 20% 80%;
    align-content: flex-start;
    align-items: center;
    background-color: white;
    font-family: "Open Sans";
    overflow: hidden;
    padding-bottom: 10px;
    flex: 0 0 auto;
    padding-top: 14px;

    &SmallPadding {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  .timeProgramme {
    display: flex;
    flex-direction: column;

    &Small {
      .startTime {
        font-size: 0.54em;
      }

      .endTime {
        font-size: 0.4em;
      }
    }
  }

  .startTime {
    color: #000;
    font-size: 0.64em;
    font-weight: bold;
    opacity: 0.8;
  }

  .endTime {
    font-size: 0.5em;
    opacity: 0.5;
  }

  .titleSession {
    font-size: 0.55em;
    font-weight: bold;
    color: #000;
    padding-right: 15px;
    // line-height: 1.3em;
    line-height: ${(props) => props.titleLineSpacing / 100 * 1.3}em;
    padding-bottom: 2px;

    // font-size: 0.4em;
  }

  .programmeContentSection {
    text-align: left;
    align-self: start;
    width: 100%;
    overflow: hidden;

    &Cut {
      .titleSession {
        text-align: start;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
      }
    }

    &CutTitle {
      .titleSession {
        -webkit-line-clamp: 1;
      }
    }

    &Grow {
      .titleSession {
        -webkit-line-clamp: 4;
      }
    }
  }

  .programmeTimeSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: start;
    height: 100%;
  }

  .statusProgramme {
    text-transform: uppercase;
    color: #fff;
    border-radius: 4px;
    padding: 2px 6px;
    margin-bottom: 6px;
    font-size: 0.46em;
  }

  .speakersSession {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-right: 15px;
    margin-top: ${(props) => props.elementsSpacing / 100 * 0.2 - 0.2}em;
    margin-bottom: ${(props) => props.elementsSpacing / 100 * 0.04 - 0.04}em;
    color: #000;
    padding-bottom: ${(props) => props.speakersLineSpacing / 100 * 0.21}em;

    p {
      line-height: ${(props) => props.speakersLineSpacing / 100 * 0.42}em;
      font-size: inherit;
      margin: 0;
    }

    &Cut {
      p {
        text-align: start;
        word-wrap: break-word;
        // TODO: Understand max height of cut and grow classes
        padding-bottom: 0.1em;
        max-height: ${(props) => props.speakersLineSpacing / 100 * 0.42 * 2}em;
      }
    }    

    &Grow {
      p {
        // TODO: Figure out. Commented out as there should not be max number of lines when grow?
        // max-height: ${(props) => props.speakersLineSpacing / 100 * 0.42 * 9}em;
      }
    }

    .speakersBottomOverlay {
      content: "";
      position: absolute;
      bottom: 0;
      left: -0.04em;
      width: 100%;
      height: 0.2em;
      background-color: #fff;
    }
  }

  .speakerWrapper {
    display: inline;
    font-size: 0.323em;
    line-height: ${(props) => props.speakersLineSpacing / 100 * 0.42}em;;
    color: #000;
  }

  .hiddenTextForSpace {
    opacity: 0;
  }

  .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: end;
    margin-top: auto;
    margin-bottom: 10%;
    height: 1.2em;

    .image {
      max-width: 70%;
    }
  }

  .imageWrapperHidden {
    display: none;
  }

  .hiddenLineForSpace {
    opacity: 0;
  }

  .autoShortenDescription {
    display: ${(props) => props.descriptionLines == 0 ? 'none' : '-webkit-box'};
    -webkit-line-clamp: ${(props) => props.descriptionLines};
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
  }

  .descriptionSession {
    position: relative;
    z-index: 2;
    span {
      line-height: ${(props) => props.descriptionLineSpacing / 100 * 1.2};
      font-size: ${(props) => props.fontScalePercentage / 100 * 22}px;
    } 

    p {
      font-size: ${(props) => props.fontScalePercentage / 100 * 22}px;
      line-height: ${(props) => props.descriptionLineSpacing / 100 * 1.2};
      margin: 0;
    }

    iframe {
      position: relative;
      z-index: 1;
    }

    &Overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      cursor: none;
    }
  }
`;
