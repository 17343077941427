const parser = new DOMParser();
const htmlFromUrl = async ({ url }) => {
  const htmlString = await (await fetch(url)).text();

  const htmlElement = parser.parseFromString(htmlString, "text/html");
  const body = htmlElement.querySelector("body");

  const extractedImageUrls = [].slice
    .call(body.querySelectorAll("img"))
    .map((img) => img.src);

  return { htmlString: body.innerHTML, extractedImageUrls };
};

export default htmlFromUrl;
