import { createSelector } from "reselect";
import moment from "moment";
import _ from "lodash";
import { getSimulatedTime } from "./app";
import { SyncDate } from "../../utils/time";

const selectorActiveScreen = (state) => state.view.activeScreen;

const getCurrentTheater = (state) => state.view.currentTheatre;

const selectorLocalTime = (state) => state.view.localTime;
const selectorContents = (state) => state.user && state.user.contents;

const getFlatDate = (date) => SyncDate.getMoment(date).format("MMMM Do YYYY");

export const getProgrammes = (state) => state.programmes.programmes;

const getSessionDateTimeAsMs = (date) =>
  SyncDate.getMoment(
    moment(date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm")
  ).valueOf();

export const getTodaysSessions = createSelector(
  [selectorLocalTime, selectorActiveScreen, selectorContents, getSimulatedTime, getCurrentTheater],
  (localTime, activeScreen, contents, simulatedTime, currentTheater) => {
    const currentTime = simulatedTime.diffMs
      ? localTime + simulatedTime.diffMs
      : localTime;

    if (!currentTheater) return { sessions: [] };

    const currentTheatreProgrammes = activeScreen.programmes.find((p) => p.id === currentTheater.id);

    if (!currentTheatreProgrammes) return { sessions: [] };

    const currentDaySessions = currentTheatreProgrammes.sessions
      .filter(
        (session) => getFlatDate(session.day) === getFlatDate(currentTime)
      )
      .map((session) => restructureDescriptionHTML(session))
      .sort((a, b) => {
        const aa = a.start.split(":").join();
        const bb = b.start.split(":").join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      })
      .filter((session) => removeElapsedSessions(session, currentTime));

    return { ...currentTheatreProgrammes, sessions: currentDaySessions };
  }
);

export const getNowSession = createSelector(
  [getTodaysSessions, selectorLocalTime, getSimulatedTime],
  (todayList, localTime, simulatedTime) => {
    const currentTime = simulatedTime.diffMs
      ? localTime + simulatedTime.diffMs
      : localTime;

    const nowSession = todayList.sessions.find((session) => {
      const startTime = getSessionDateTimeAsMs(
        `${session.day} ${session.start}`
      );
      const endTime = getSessionDateTimeAsMs(`${session.day} ${session.end}`);
      return (startTime <= currentTime && currentTime <= endTime) || false;
    });
    return nowSession;
  }
);

export const getNowSessions = createSelector(
  [getTodaysSessions, selectorLocalTime, getSimulatedTime],
  (todayList, localTime, simulatedTime) => {
    const currentTime = simulatedTime.diffMs
      ? localTime + simulatedTime.diffMs
      : localTime;

    const nowSessions = todayList.sessions.filter((session) => {
      const startTime = getSessionDateTimeAsMs(
        `${session.day} ${session.start}`
      );
      const endTime = getSessionDateTimeAsMs(`${session.day} ${session.end}`);
      return (startTime <= currentTime && currentTime <= endTime) || false;
    });
    return nowSessions;
  }
);

export const getNextSession = createSelector(
  [getTodaysSessions, selectorLocalTime, getNowSession, getSimulatedTime],
  (todayList, localTime, nowSession, simulatedTime) => {
    const currentTime = simulatedTime.diffMs
      ? localTime + simulatedTime.diffMs
      : localTime;
    const nextSession = todayList.sessions
      .filter((item) => item.id !== (nowSession && nowSession.id))
      .find((session) => {
        const startTime = getSessionDateTimeAsMs(
          `${session.day} ${session.start}`
        );
        const endTime = getSessionDateTimeAsMs(`${session.day} ${session.end}`);
        return (startTime >= currentTime && currentTime <= endTime) || false;
      });

    return nextSession;
  }
);

export const getNextSessions = createSelector(
  [getTodaysSessions, selectorLocalTime, getNowSessions, getSimulatedTime],
  (todayList, localTime, nowSessions, simulatedTime) => {
    const currentTime = simulatedTime.diffMs
      ? localTime + simulatedTime.diffMs
      : localTime;
    const upcommingSessions = todayList.sessions
      .filter((item) => !nowSessions.find((nowSession) => nowSession.id === item.id));
    const nextSession = upcommingSessions
      .find((session) => {
        const startTime = getSessionDateTimeAsMs(
          `${session.day} ${session.start}`
        );
        const endTime = getSessionDateTimeAsMs(`${session.day} ${session.end}`);
        return (startTime >= currentTime && currentTime <= endTime) || false;
      });
    if (!nextSession) return [];
    const restOfNextSessions = upcommingSessions
      .filter((item) => item.id !== (nextSession && nextSession.id))
      .filter((item) => item.start === nextSession.start);
    restOfNextSessions.unshift(nextSession);
    return restOfNextSessions;
  }
);

export const getTodaysRemainingSessions = createSelector(
  [getTodaysSessions, getNowSessions, getNextSessions],
  (toDayList, nowSessions, nextSessions) => {
    const restSessionList = toDayList.sessions
      .filter((item) => !nowSessions.find((nowSession) => nowSession.id === item.id))
      .filter((item) => !nextSessions.find((nextSession) => nextSession.id === item.id));
    return restSessionList;
  }
);

export const getTomorrowList = createSelector(
  [selectorLocalTime, selectorActiveScreen, selectorContents, getSimulatedTime, getCurrentTheater],
  (localTime, activeScreen, contents, simulatedTime, currentTheater) => {
    if (!currentTheater) return { sessions: [] };

    const currentTheatreProgrammes = activeScreen.programmes.find((p) => p.id === currentTheater.id);

    if (!currentTheatreProgrammes) return { sessions: [] };

    const currentTime = simulatedTime.diffMs
      ? localTime + simulatedTime.diffMs
      : localTime;

    const nextDaysProgramme = _.compact(
      [...Array(7).keys()]
        .filter((day) => day !== 0)
        .map((daysFromNow) => {
          const nextDay = moment(currentTime).add(daysFromNow, "days");

          const currentDaySessions = currentTheatreProgrammes.sessions
            .filter(
              (session) => getFlatDate(session.day) === getFlatDate(nextDay)
            )
            .sort((a, b) => {
              const aa = a.start.split(":").join();
              const bb = b.start.split(":").join();
              return aa < bb ? -1 : aa > bb ? 1 : 0;
            });

          if (currentDaySessions.length !== 0) {
            return {
              sessions: currentDaySessions,
              nextDayOfSessionsName:
                daysFromNow === 1 ? "Tomorrow" : nextDay.format("dddd"),
            };
          }
        })
    );

    return {
      ...currentTheatreProgrammes,
      sessions: [],
      ...nextDaysProgramme[0],
    };
  }
);

export const restructureDescriptionHTML = (session, screenId, contents) => {
  const { description } = session;
  const htmlElement = document.createElement("html");

  htmlElement.innerHTML = description;

  //remove style tags as they affect output
  Array.prototype.slice
    .call(htmlElement.getElementsByTagName("style"))
    .forEach((item) => {
      item.remove();
    });
  // debugger;
  return { ...session, description: htmlElement.outerHTML };
};

const removeElapsedSessions = (session, localTime) => {
  // console.log('${session.day} ${session.start}', `${session.day} ${session.start}`);
  const startTime = getSessionDateTimeAsMs(`${session.day} ${session.start}`);
  const currentTime = localTime;
  const endTime = getSessionDateTimeAsMs(`${session.day} ${session.end}`);

  if (startTime <= currentTime && currentTime <= endTime) {
    return true;
  }

  return !(currentTime > endTime);
};
