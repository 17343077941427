import { SyncDate } from "./time";

import PortalApi from "./eventignitePortalApi";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

function ntp(t0, t1, t2, t3) {
  return {
    roundtripDelay: t3 - t0 - (t2 - t1),
    offset: (t1 - t0 + (t2 - t3)) / 2,
  };
}

export const getClockOffsetOneTime = async () => {
  const sentTime = Date.now();

  let response = null;

  try {
    response = await PortalApi.get(`/Clients/time?time=${sentTime}`);
    if (
      !response.data ||
      !response.data.timeFromMidnight ||
      !response.data.timestamp
    )
      return {
        networkLatency: null,
        timezoneOffset: null,
        offset: null,
        serverMidnight: null,
      };
  } catch (error) {
    throw new Error("Clock server offline");
  }

  const { timeFromMidnight, timestamp } = response.data;

  const responseTime = Date.now();
  const networkLatency = responseTime - sentTime;
  const serverTime = timestamp;
  const serverTimeFromMidnight = timeFromMidnight;
  const t0 = sentTime;
  const t1 = serverTime;
  const t2 = serverTime;
  const t3 = responseTime;

  const NTP = ntp(t0, t1, t2, t3);

  const clientMidnightTimestamp = SyncDate.getClientMidnight();
  const clientTimeFromMidnight = Date.now() - clientMidnightTimestamp;
  const timezoneOffset = serverTimeFromMidnight - clientTimeFromMidnight;
  const serverMidnight = serverTime - serverTimeFromMidnight;

  console.log("==getClockOffsetOneTime DELAY==");
  delay(getRandomInt(0, 500));
  return {
    networkLatency,
    timezoneOffset,
    offset: NTP.offset,
    serverMidnight,
  };
};

// Not used in this version
export const calculateClockDifference = async (numberOfRequests = 10) => {
  const iterations = [...Array(numberOfRequests).keys()];
  const promises = iterations.map(() => getClockOffsetOneTime);
  const results = [];

  await promises.reduce(async (previousPromise, nextAsyncFunction) => {
    await previousPromise;
    const result = await nextAsyncFunction();
    results.push(result);
  }, Promise.resolve());

  // results sort by latency from lowest
  const sortedByLatency = results.sort(
    (a, b) => a.networkLatency - b.networkLatency
  );
  const latencyMean =
    sortedByLatency[Math.floor(sortedByLatency.length / 2)].networkLatency;
  const standardDeviation = Math.sqrt(
    sortedByLatency.reduce((acc, i) => {
      const deviation =
        (i.networkLatency - latencyMean) * (i.networkLatency - latencyMean);
      return acc + deviation;
    }, 0) / numberOfRequests
  );
  // All samples above approximately 0ю5 standard-deviation from the lowest latency are discarded and the remaining samples are averaged using an arithmetic mean.
  const minLatency = sortedByLatency[0].networkLatency;
  const leftBound = minLatency;
  const rightBound = minLatency + Math.round(standardDeviation / 2);
  const filteredResults = sortedByLatency.filter(
    (res) => res.networkLatency >= leftBound && res.networkLatency <= rightBound
  );

  const averageTimezoneOffset =
    filteredResults.reduce((acc, i) => acc + i.timezoneOffset, 0) /
    filteredResults.length;
  const averageOffset =
    filteredResults.reduce((acc, i) => acc + i.offset, 0) /
    filteredResults.length;

  const { serverMidnight } = filteredResults[0];

  return {
    timezoneOffset: Math.round(averageTimezoneOffset),
    offset: Math.round(averageOffset),
    serverMidnight,
  };
};
