import React from "react";
import { DialogTitle, withStyles } from "@material-ui/core";
import styled from "styled-components";

const StyledTitle = styled.span`
  color: white;
`;

const styles = (theme) => ({
  h2: {
    color: "white",
  },
  dialogTitle: {
    background: "black",
  },
});

function DialogTitleWithCloseIcon(props) {
  const { title, classes } = props;
  return (
    <DialogTitle className={classes.dialogTitle}>
      <StyledTitle>{title}</StyledTitle>
    </DialogTitle>
  );
}

const styledComponent = withStyles(styles, { withTheme: true })(
  DialogTitleWithCloseIcon
);

export default styledComponent;
