export { default as restructureScreens } from "./restructure-screens";
export { default as getCollectedProgrammes } from "./get-collected-programmes";
export { default as getHtmlWithFonts } from "./get-html-with-fonts";
export { default as getIframeDocument } from "./get-iframe-document";
export { default as addContentMetaToPlaylist } from "./add-content-meta-to-playlist";
export { default as getNestedScreenContentMeta } from "./get-nested-screen-content-meta";
export { default as addContentEntityToPlaylist } from "./add-content-entity-to-playlist";
export { default as getRandomDeviceName } from "./get-random-device-name";

export const safeGet = (getFunc, defaultValue = null) => {
  try {
    return getFunc();
  } catch (e) {
    return defaultValue;
  }
};
