import React, { Fragment } from "react";
import { format, formatDistance } from "date-fns";
import _ from "lodash";

import { subMinutes } from "date-fns/esm";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import LoopIcon from "@material-ui/icons/Loop";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import WebIcon from "@material-ui/icons/Web";
import ImageIcon from "@material-ui/icons/Image";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";

function AvailableContent({ availableContentItems }) {
  if (!availableContentItems || availableContentItems.length === 0) {
    return <p style={{ fontSize: 40 }}>Checking downloaded items...</p>;
  }

  const renderItemType = ({ type }) => {
    switch (type) {
      case "html":
        return (
          <span style={{ color: "#20639B" }}>
            <WebIcon />
          </span>
        );
      case "video":
        return (
          <span style={{ color: "#3CAEA3" }}>
            <OndemandVideoIcon />
          </span>
        );
      case "image":
        return (
          <span style={{ color: "#173F5F" }}>
            <ImageIcon />
          </span>
        );
      case "programme":
        return (
          <span style={{ color: "white" }}>
            <FormatListBulletedIcon />
          </span>
        );
      default:
        return "";
    }
  };

  const renderAvailableLabel = ({ downloadStatus, type }) => {
    if (type === "programme")
      return (
        <span style={{ color: "lightgreen", paddingLeft: 15 }}>
          {" "}
          <CloudDownloadIcon />
        </span>
      );
    switch (downloadStatus) {
      case 0:
        return (
          <span style={{ color: "red", paddingLeft: 15 }}>
            <WarningIcon />
          </span>
        );
      case 1:
        return (
          <span style={{ color: "orange", paddingLeft: 15 }}>
            {" "}
            <CloudDownloadIcon />
          </span>
        );
      case 2:
        return (
          <span style={{ color: "lightgreen", paddingLeft: 15 }}>
            <CloudDownloadIcon />
          </span>
        );
      case -1:
        return (
          <span style={{ color: "purple", paddingLeft: 15 }}>
            <ErrorIcon />
          </span>
        );

      default:
        return (
          <span style={{ color: "grey", paddingLeft: 15 }}>
            <LoopIcon />
          </span>
        );
    }
  };

  return (
    <Fragment>
      <div>Downloaded Content</div>

      <ul style={{ height: 1100, overflowY: "scroll" }}>
        {availableContentItems.map((item) => (
          <li
            key={item.content}
            style={{
              marginTop: 5,
              fontSize: 22,
              textAlign: "left",
              listStyle: "none",
            }}
          >
            {renderItemType({ type: item.type })}
            <span style={{ paddingLeft: 10 }}>{item.name}</span>
            {renderAvailableLabel(item)}
            <span style={{ paddingLeft: 10 }}>
              {" "}
              checked: &nbsp;{" "}
              <i>
                {formatDistance(new Date(), item.updated, {
                  includeSeconds: true,
                })}
              </i>
            </span>
          </li>
        ))}
      </ul>
    </Fragment>
  );
}

export default AvailableContent;
