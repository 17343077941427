import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent, Box, withStyles } from "@material-ui/core";
import DialogTitleWithCloseIcon from "./DialogTitleWithCloseIcon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import { setActiveScreen } from "../../../redux/actions/view";

const styles = (theme) => ({
  dialogPaper: {
    maxWidth: 420,
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  dialogPaperScrollPaper: {
    maxHeight: "none",
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 300,
  },
  "MuiListItem-gutters": {
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

class SelectScreenModal extends Component {
  renderScreensList = () => {
    const {
      onClose,
      modalOptions: { screensList, accessCode },
    } = this.props;

    const switchScreen = (screen) => {
      window.location.href = `/${accessCode}/screen/${screen.id}${window.location.search}`;
    };

    return (
      <List>
        {screensList.map((screen) => {
          return (
            <ListItem key={screen.id} onClick={() => switchScreen(screen)}>
              <ListItemText primary={screen.name} />
            </ListItem>
          );
        })}
      </List>
    );
  };

  render() {
    const {
      open,
      modalOptions: { headline, screensList },
      onClose,
      disabled,
      hasCloseIcon,
      classes,
    } = this.props;

    return (
      <Dialog
        open={open}
        onBackdropClick={onClose}
        classes={{
          paper: classes.dialogPaper,
        }}
        scroll="paper"
      >
        <DialogTitleWithCloseIcon
          title={headline}
          onClose={onClose}
          disabled={disabled}
          hidden={!hasCloseIcon}
        />
        <DialogContent dividers={true} className={classes.dialogContent}>
          <div>{this.renderScreensList()}</div>
        </DialogContent>
      </Dialog>
    );
  }
}

const styledModal = withStyles(styles, { withTheme: true })(SelectScreenModal);

export default connect(null, { setActiveScreen })(styledModal);
