import React, { Component } from "react";

import Dexie from "dexie";

import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
import ControlPanel from "./ControlPanel";
import DefaultScreen from "./DefaultScreen";
import SplitScreen from "./SplitScreen";
import TheatreScreen from "./TheatreScreen";
import LandscapeScreen from "./LandscapeScreen";

import Grid from "@material-ui/core/Grid";

import logo from "../../assets/logo.png";

import getRandomDeviceName from "../../utils/get-random-device-name";

const URL_TIME_DATE_FORMAT = "YYYY-MM-DD-HHmm";

const extractUrlParams = (rawUrl) =>
  _.chain(rawUrl)
    .replace("?", "") // a=b454&c=dhjjh&f=g6hksdfjlksd
    .split("&") // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
    .map(_.partial(_.split, _, "=", 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
    .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
    .value();

const getStartTimeAsUnixTimestap = ({ startDateAndTime }) => {
  const startMs = moment(
    `${startDateAndTime}`,
    URL_TIME_DATE_FORMAT,
    true
  ).valueOf();

  return startMs;
};

const StyledView = styled.div`
  background-color: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: auto;

  &ViewportWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    background: #000;
  }

  .Viewport {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
  }

  .hideCursor {
    cursor: none;
  }
`;

export default class View extends Component {
  constructor(props) {
    super(props);
    const { setSimulatedTime } = this.props;

    // props.location.search = "?simTime=2020-11-02-1117";

    const urlParams = extractUrlParams(props.location.search);
    if (urlParams && urlParams.simTime) {
      const startDateAndTime = urlParams.simTime;
      const startMs =
        startDateAndTime && getStartTimeAsUnixTimestap({ startDateAndTime });
      // const simTimeDate = new Date(startMs);

      setSimulatedTime({ startMs });
    }

    this.state = {
      showControlPanel: false,
      insideIFrame: false,
      deviceName: getRandomDeviceName(),
      startedAt: new Date(),
      enabledSound: false,
    };

    this.throttledOnMouseMove = _.throttle(
      this.throttleOnMouseMove.bind(this),
      500
    );
  }

  clearOfflineDatabaseAndReload = () => {
    this.offlineDB
      .delete()
      .then(() => {
        console.log("Database successfully deleted");
      })
      .catch((err) => {
        console.error("Could not delete database");
      })
      .finally(() => {
        window.location.reload();
      });
  };

  componentDidMount() {
    const {
      fetchScreensData,
      pingMonitoringService,
      saveOrUpdateResourcesMetadataToOfflineDB,
      match: {
        params: { access_code, mode },
      },
      queueItemsToDownload,
    } = this.props;

    // Don't use offline db if the player is embedded or is not supported

    let insideIFrame = false;

    if (window.location.href !== window.parent.location.href) {
      console.log(
        "The page is in an iFrame",
        window.parent.location.href,
        window.location.href
      );
      insideIFrame = true;
      this.setState({ insideIFrame });
    }

    if (!insideIFrame && mode !== "lite" && indexedDB) {
      this.offlineDB = new Dexie("offlineDB");
      this.offlineDBAvailable = true;

      this.offlineDB.version(1).stores({
        fileStore:
          "content,type,downloadStatus,updated,[content+downloadStatus],[content+type]",
        binaryStore: "content",
      });

      this.offlineDB.open().catch((err) => {
        this.offlineDBAvailable = false;
        console.error("Failed to open db: " + (err.stack || err));
      });

      //Reset downloads in progress, usually on force page refresh
      this.offlineDB.fileStore
        .where("downloadStatus")
        .equals(1)
        .modify({ downloadStatus: 0 })
        .then(() => console.log(`Updated downloadStatus`))
        .catch((error) => {
          if (
            error.name === "QuotaExceededError" ||
            (error.inner && error.inner.name === "QuotaExceededError")
          ) {
            console.log(
              "If you see this message, then error handling works as expected.",
              error
            );
          } else {
            console.log("Here is something wrong:", error);
            this.offlineDBAvailable = false;
          }
        });

      setTimeout(() => {
        if (this.offlineDBAvailable) {
          queueItemsToDownload({ db: this.offlineDB });
        } else {
          console.log("Caching items without database");
        }
      }, 5000);
    }

    // Ping monitoring service
    if (!insideIFrame) {
      this.pingInterval = setInterval(() => {
        pingMonitoringService({
          name: this.state.deviceName,
          eventSlug: access_code,
          startedAt: this.state.startedAt.getTime(),
        });
      }, 60 * 1000);
    }

    //Fetch data
    fetchScreensData({ access_code });

    if (mode !== "lite") {
      setTimeout(() => {
        this.offlineDBAvailable &&
          saveOrUpdateResourcesMetadataToOfflineDB({ db: this.offlineDB });
      }, 1000);
    }

    this.localTime = setInterval(this.timerTick, 1000);

    this.changeModeToLiveTimer = setTimeout(
      this.changeModeToLive,
      30 * 60 * 1000
    );

    this.startBackgroundUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      setActiveScreen,
      activeScreen,
      currentPlaylistIndex,
      splitCurrentIndexFirst,
      splitCurrentIndexSecond,
      screensList,
      setOpenScreenSelectModal,
      match: { params },
      updateContentCache,
      isSimulatedTime,
    } = this.props;

    // console.log({ params });

    const {
      currentPlaylistIndex: prevCurrentPlaylistIndex,
      splitCurrentIndexFirst: prevSplitIndexFirst,
      splitCurrentIndexSecond: prevSplitIndexSecond,
      isSimulatedTime: prevIsSimulatedTime,
    } = prevProps;

    if (prevIsSimulatedTime !== isSimulatedTime) {
      clearInterval(this.updateInterval);
      clearInterval(this.resourceDownloadInterval);
      clearInterval(this.resourceUpdateInterval);
      clearInterval(this.synchronizeInterval);

      this.startBackgroundUpdate();
    }

    if (
      prevCurrentPlaylistIndex !== currentPlaylistIndex ||
      prevSplitIndexFirst !== splitCurrentIndexFirst ||
      prevSplitIndexSecond !== splitCurrentIndexSecond
    ) {
      updateContentCache({ db: this.offlineDB, props: this.props });
    }

    const screenId = parseInt(params.id);

    if (screensList && screensList.length > 0 && screenId) {
      const screen = screensList.find(({ id }) => id === screenId);

      if (
        screenId === 0 ||
        (activeScreen && activeScreen.id === screenId) ||
        !screen
      )
        return;

      setActiveScreen(screen);
    } else if (isNaN(screenId) && screensList && !activeScreen) {
      // path without Screen ID -> select first screen
      const lastActiveScreenId = localStorage.getItem("lastActiveScreen");

      const lastActiveScreen = screensList.find(
        (screen) => screen.id === parseInt(lastActiveScreenId)
      );

      const lastActiveScreenBelongsToCurrentEvent =
        screensList.filter(
          (screen) => screen.id === parseInt(lastActiveScreenId)
        ).length > 0;

      if (lastActiveScreen && lastActiveScreenBelongsToCurrentEvent) {
        // This creates endless
        setActiveScreen(lastActiveScreen);
        window.location.href = `/${params.access_code}/screen/${lastActiveScreenId}`
      } else {
        setActiveScreen(screensList[0]);
        setOpenScreenSelectModal({
          headline: "Select Screen:",
          screensList,
          accessCode: params.access_code,
        });
      }
    }
  }

  setViewportTransform = (key, value) => {
    const { setViewportTransform } = this.props;
    setViewportTransform(key, value);
  };

  handleScaleUp = () => this.setViewportTransform("scale", +0.01);

  handleScaleDown = () => this.setViewportTransform("scale", -0.01);

  handleScaleReset = () => {
    const { viewportResetScale } = this.props;
    viewportResetScale();
  };

  handleUp = (howManyPixels = 1) =>
    this.setViewportTransform("y", -howManyPixels);

  handleDown = (howManyPixels = 1) =>
    this.setViewportTransform("y", +howManyPixels);

  handleLeft = (howManyPixels = 1) =>
    this.setViewportTransform("x", -howManyPixels);

  handleRight = (howManyPixels = 1) =>
    this.setViewportTransform("x", +howManyPixels);

  handleToggleSound = () =>
    this.setState((prevState) => ({ enabledSound: !prevState.enabledSound }));

  componentWillUnmount() {
    this.clearTimers();
  }

  startBackgroundUpdate() {
    const {
      fetchScreensData,
      queueItemsToDownload,
      saveOrUpdateResourcesMetadataToOfflineDB,
      synchronizeContentNoApiCall,
      updateRestructuredPlaylists,
      match: {
        params: { access_code },
      },
      location,
      isSimulatedTime,
    } = this.props;

    //if screen is in Preview mode, increase update frequency to every 5 secs vs 5 minutes
    const urlParams = extractUrlParams(location.search);
    const secondsUntilNextUpdate = isSimulatedTime ? 10 : 2 * 60;
    const timeInterval = 1000 * secondsUntilNextUpdate;

    this.updateInterval = setInterval(() => {
      fetchScreensData({ access_code, db: this.offlineDB });
    }, timeInterval);

    this.synchronizeTimeout = setTimeout(synchronizeContentNoApiCall, 2000);

    this.synchronizeInterval = setInterval(
      synchronizeContentNoApiCall,
      60 * 1000
    );

    this.updatePlaylistInterval = setInterval(() => {
      updateRestructuredPlaylists();
    }, 5000);

    const secondsUntilNextResourceUpdate =
      urlParams && urlParams.simTime ? 15 : 30;
    const resourceTimeInterval = 1000 * secondsUntilNextResourceUpdate;

    this.resourceUpdateInterval = setInterval(() => {
      // console.log("resourceUpdateInterval");
      this.offlineDBAvailable &&
        saveOrUpdateResourcesMetadataToOfflineDB({ db: this.offlineDB });
    }, (resourceTimeInterval + 7000) * 3);

    this.resourceDownloadInterval = setInterval(() => {
      // console.log("resourceDownloadInterval");

      this.offlineDBAvailable && queueItemsToDownload({ db: this.offlineDB });
    }, resourceTimeInterval);
  }

  timerTick = () => {
    const { setLocalTime } = this.props;
    setLocalTime();
  };

  clearTimers() {
    clearInterval(this.localTime);
    clearInterval(this.updateInterval);
    clearInterval(this.resourceDownloadInterval);
    clearInterval(this.resourceUpdateInterval);
    clearInterval(this.synchronizeInterval);
    clearTimeout(this.synchronizeTimeout);
    clearTimeout(this.pingInterval);
    clearInterval(this.updatePlaylistInterval);
    clearInterval(this.synchronizeInterval);
  }

  changeModeToLive = () => {
    const {
      activeScreen: { id },
      match: {
        params: { access_code },
      },
      isSimulatedTime,
    } = this.props;

    if (isSimulatedTime) {
      console.log("changing mode to Live");
      window.location = `/${access_code}/screen/${id}`;
    }
  };

  hideControlPanel = () => {
    const { isSimulatedTime } = this.props;

    this.setState({ showControlPanel: false });
  };

  delayChangeModeToLive = () => {
    clearTimeout(this.changeModeToLiveTimer);
    this.changeModeToLiveTimer = setTimeout(
      this.changeModeToLive,
      30 * 60 * 1000
    );
  };

  throttleOnMouseMove = () => {
    // console.log("throttlemousemove!");
    this.setState({ showControlPanel: true });

    clearTimeout(this.hideControlPanelTimer);
    this.hideControlPanelTimer = setTimeout(this.hideControlPanel, 7 * 1000);
  };

  handleOnMouseInteraction = () => {
    this.delayChangeModeToLive();
    this.throttledOnMouseMove();
  };

  selectScreenType = () => {
    const { enabledSound } = this.state;
    const {
      activeScreen: { type, options, screenSize, programmes, screenSizeId },
      activeScreen,
      playlistContent,
      currentPlaylistIndex,
      simulatedTime,
      offset,
      setCurrentViewContent,
      currentViewContent,
      setCurrentSyncedContent,
      setCurrentIndex,
      playlistContentSplitScreenFirst,
      playlistContentSplitScreenSecond,
      synchronizeContentNoApiCall,
      currentViewContentData,
      currentContent,
    } = this.props;

    const sharedProps = {
      simulatedTime,
      offset,
      setCurrentViewContent,
      synchronizeContentNoApiCall,
      setCurrentSyncedContent,
      setCurrentIndex,
      playlistContent,
      currentPlaylistIndex,
      currentViewContent,
      screenSize,
      activeScreen,
      currentViewContentData,
      currentContent,
      enabledSound
    };

    // console.log({ viewProps: this.props });

    //TODO: remove font hack
    if (window.location.href.includes("ihif20") && options) {
      options.fontFamily = "Raleway, Open Sans, sans-serif";
    }

    switch (type) {
      case "default":
        //Landscape size ID
        if (screenSizeId === 116) return <LandscapeScreen {...sharedProps} />;
        return <DefaultScreen {...sharedProps} />;
      case "split":
        return (
          <SplitScreen
            {...sharedProps}
            playlistContentSplitScreenFirst={playlistContentSplitScreenFirst}
            playlistContentSplitScreenSecond={playlistContentSplitScreenSecond}
          />
        );
      case "theatre":
        return (
          <TheatreScreen
            {...sharedProps}
            options={options}
            programmes={programmes}
          />
        );
      default:
        break;
    }
  };

  render() {
    const {
      activeScreen,
      viewportTransform,
      simulatedTime,
      setSimulatedTime,
      clearSimulatedTime,
      isSimulatedTime,
      setOpenScreenSelectModal,
      closeModal,
      screensList,
      availableContentItems,
      currentPlaylistIndex,
      playlistContent,
      currentViewContentData,
      playlistContentSplitScreenFirst,
      playlistContentSplitScreenSecond,
      setCurrentIndex,
      localTime,
      synchronizeContentNoApiCall,
      match: { params },
    } = this.props;

    const { showControlPanel, insideIFrame, enabledSound } = this.state;

    const viewportStyle = {
      transform: `
        translateX(${viewportTransform.x}px)
        translateY(${viewportTransform.y}px)
        scale(${viewportTransform.scale})
      `,
    };

    if (!activeScreen)
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={6}>
            <img style={{ width: "100%" }} src={logo} />
          </Grid>
        </Grid>
      );
    return (
      <StyledView className="ViewportWrapper ">
        <div
          className="Viewport hideCursor"
          style={viewportStyle}
          onMouseMove={this.handleOnMouseInteraction}
          onClick={this.handleOnMouseInteraction}
        >
          <ControlPanel
            show={showControlPanel}
            hideControlPanel={this.hideControlPanel}
            simulatedTime={simulatedTime}
            setSimulatedTime={setSimulatedTime}
            clearSimulatedTime={clearSimulatedTime}
            isSimulatedTime={isSimulatedTime}
            activeScreen={activeScreen}
            accessCode={params.access_code}
            screenMode={params.mode}
            setOpenScreenSelectModal={setOpenScreenSelectModal}
            closeModal={closeModal}
            screensList={screensList}
            mouseMoved={this.handleOnMouseInteraction}
            availableContentItems={availableContentItems}
            playlistContent={playlistContent}
            currentPlaylistIndex={currentPlaylistIndex}
            currentViewContentData={currentViewContentData}
            playlistContentSplitScreenFirst={playlistContentSplitScreenFirst}
            playlistContentSplitScreenSecond={playlistContentSplitScreenSecond}
            handleScaleUp={this.handleScaleUp}
            handleScaleDown={this.handleScaleDown}
            handleScaleReset={this.handleScaleReset}
            handleUp={this.handleUp}
            handleDown={this.handleDown}
            handleLeft={this.handleLeft}
            handleRight={this.handleRight}
            enabledSound={enabledSound}
            handleToggleSound={this.handleToggleSound}
            insideIFrame={insideIFrame}
            clearOfflineDatabaseAndReload={this.clearOfflineDatabaseAndReload}
            setCurrentIndex={setCurrentIndex}
            localTime={localTime}
            synchronizeContentNoApiCall={synchronizeContentNoApiCall}
          />
          {this.selectScreenType()}
        </div>
      </StyledView>
    );
  }
}
