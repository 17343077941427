import { createSelector } from 'reselect';

export const getContentGroup = (state) => state.screens.contentGroup;
export const selectorScreenList = (state) => state.screens.screensList;

export const getScreensList = createSelector(
  [selectorScreenList],
  (screensList) => {
    return screensList;
  }
);
