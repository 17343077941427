import styled from "styled-components";

const StyledTodaysSession = styled.div`
  background-color: white;
  display: grid;
  position: relative;
  z-index: 1;
  font-size: ${(props) => props.fontScalePercentage}%;

  .programme {
    overflow: hidden;
    position: relative;
    display: block;
  }

  .hide {
    overflow: hidden;
    position: absolute;
    left: 1000%;
  }

  .infoText {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: 300;
    color: #000;
    border-top: 1px solid ${(props) => props.hex};
    font-family: ${(props) => props.fontFamily};
  }
`;

export { StyledTodaysSession };
