import React, { PureComponent } from "react";
import styled from "styled-components";
import DefaultScreen from "../DefaultScreen";

const StyledLandscapeScreen = styled.div`
  background-color: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  text-align: center;
  background: #000;
  cursor: none;

  .landscapeWrapper {
    // border: 5px solid red;
    margin: auto;
    display: grid;
    grid-auto-rows: auto;
    overflow: hidden;
  }
`;

class LandscapeScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.rootRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    this.adjustContentToScreen();
  }

  componentDidUpdate() {
    this.adjustContentToScreen();
  }
  adjustContentToScreen = () => {
    let currentRoot = this.rootRef.current;
    let currentWrapper = this.wrapperRef.current;

    let { clientHeight, clientWidth } = currentRoot;

    const resolutionHeight = 1080;
    const resolutionWidth = 1920;

    const adjust = () => {
      let scale =
        resolutionHeight >= resolutionWidth
          ? clientHeight / resolutionHeight
          : clientWidth / resolutionWidth;

      // Adjust for very narrow viewports
      let yPosition = 0;
      if (clientWidth < resolutionWidth * scale) {
        scale = clientWidth / resolutionWidth;
        const theatreWrapperHeight =
          currentWrapper.getBoundingClientRect().height;
        yPosition = clientHeight / 2 - theatreWrapperHeight / 2;
      }
      // console.log('clientWidth', clientWidth);
      // console.log('resolutionWidth*scale', resolutionWidth * scale);
      // const scale = 1;

      const position = "0%";
      const style = ` 
        width: ${resolutionWidth}px;
        height: ${resolutionHeight}px;
        position: absolute;
        transform: scale(${scale});
        transform-origin: left top;
      `;

      currentRoot.style.cssText = "position: relative;";
      currentWrapper.style.cssText = ` ${style}`;
      const viewportWidth = currentRoot.getBoundingClientRect().width;
      const theatreWrapperWidth = currentWrapper.getBoundingClientRect().width;

      const xPosition =
        clientWidth > theatreWrapperWidth
          ? clientWidth / 2 - theatreWrapperWidth / 2
          : 0;
      currentWrapper.style.left = `${xPosition}px`;
      currentWrapper.style.top = `${yPosition}px`;
    };

    adjust();
  };

  render() {
    return (
      <StyledLandscapeScreen ref={this.rootRef}>
        <div ref={this.wrapperRef} className="landscapeWrapper">
          <DefaultScreen {...this.props} />
        </div>
      </StyledLandscapeScreen>
    );
  }
}

export default LandscapeScreen;
