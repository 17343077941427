export const constants = {
  SET_SCREENS_LIST: "screens/SET_SCREENS_LIST",
  FETCH_SCREENS_SUCCESS: "screens/FETCH_SCREENS_SUCCESS",
  FETCH_SCREENS_FAILURE: "screens/FETCH_SCREENS_FAILURE",
  FETCH_MESSAGES_HTML: "view/FETCH_MESSAGES_HTML",
  SET_ACTIVE_SCREEN: "view/SET_ACTIVE_SCREEN",
  REMOVE_ACTIVE_SCREEN: "view/REMOVE_ACTIVE_SCREEN",
  SET_LOCAL_TIME: "view/SET_LOCAL_TIME",
  FETCH_USER_DATA_SUCCESS: "user/FETCH_USER_DATA_SUCCESS",
  CLEAR_SIMULATED_TIME: "app/CLEAR_SIMULATED_TIME",
  SET_SIMULATED_TIME: "app/SET_SIMULATED_TIME",
  CLOCK_SYNC_START: "app/CLOCK_SYNC_START",
  SET_SERVER_TIME: "app/SET_SERVER_TIME",
  SET_CURRENT_CONTENT: "view/SET_CURRENT_CONTENT",
  SET_RESTRUCTURED_PLAYLIST_CONTENT: "view/SET_RESTRUCTURED_PLAYLIST_CONTENT",
  SET_CURRENT_INDEX: "view/SET_CURRENT_INDEX",
  SET_CURRENT_VIEW_CONTENT: "view/SET_CURRENT_VIEW_CONTENT",
  SET_ADDITIONAL_CURRENT_VIEW_CONTENT:
    "view/SET_ADDITIONAL_CURRENT_VIEW_CONTENT",
  SET_CURRENT_VIEW_CONTENT_DATA: "view/SET_CURRENT_VIEW_CONTENT_DATA",
  SET_MODAL_OPEN_SCREEN_SELECT: "modal/SET_MODAL_OPEN_SCREEN_SELECT",
  CLOSE_MODAL: "modal/CLOSE_MODAL",
  FETCH_IMAGES_AND_VIDEOS_SUCCESS: "resources/FETCH_IMAGES_AND_VIDEOS_SUCCESS",
  FETCH_HTML_MESSAGES_SUCCESS: "resources/FETCH_HTML_MESSAGES_SUCCESS",
  INCREMENT_RESOURCE_REFRESH_INDEX:
    "resources/INCREMENT_RESOURCE_REFRESH_INDEX",
  INCREMENT_RESOURCE_DOWNLOAD_INDEX:
    "resources/INCREMENT_RESOURCE_DOWNLOAD_INDEX",
  SET_ITEMS_TO_DOWNLOAD: "resources/SET_ITEMS_TO_DOWNLOAD",
  SET_AVAILABLE_CONTENT_ITEMS: "resources/SET_AVAILABLE_CONTENT_ITEMS",
  SET_EXTRACTED_HTML_IMAGES: "resources/SET_EXTRACTED_HTML_IMAGES",
  SET_FIRST_SPLIT_CONTENT: "view/SET_FIRST_SPLIT_CONTENT",
  SET_SECOND_SPLIT_CONTENT: "view/SET_SECOND_SPLIT_CONTENT",
  SET_VIEWPORT_TRANSFORM: "view/SET_VIEWPORT_TRANSFORM",
  VIEWPORT_RESET_SCALE: "view/VIEWPORT_RESET_SCALE",
  SET_CURRENT_THEATRE: "view/SET_CURRENT_THEATRE",
  SET_CURRENT_PLAYLIST: "view/SET_CURRENT_PLAYLIST"
};
