import React, { Component } from "react";
import { connect } from "react-redux";

import styled from "styled-components";
import BottomBar from "../BottomBar";
import DefaultScreen from "../DefaultScreen";
import {
  setFirstSplitContent,
  setSecondSplitContent,
  setCurrentViewContent,
  setAdditionalCurrentViewContent,
} from "../../../redux/actions/view";

import { defineSplitScreenStyles } from "../../../redux/selectors/view";

const StyledSplitScreen = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  cursor: none;
`;

const isFullScreen = (props) => {
  const {
    options,
    currentPlaylistIndexSplitScreenFirst,
    currentPlaylistIndexSplitScreenSecond,
    playlistContentSplitScreenFirst,
    playlistContentSplitScreenSecond,
  } = props;
  const {
    sizes: { firstPlaylist, secondPlaylist, bottomBar },
  } = options;

  const currentFirst =
    playlistContentSplitScreenFirst[currentPlaylistIndexSplitScreenFirst] ||
    playlistContentSplitScreenFirst[0];
  const currentSecond =
    playlistContentSplitScreenSecond[currentPlaylistIndexSplitScreenSecond] ||
    playlistContentSplitScreenSecond[0];
  const { isFullScreen: firstIsFull } = currentFirst || {};
  const { isFullScreen: secondIsFull } = currentSecond || {};

  return firstIsFull || secondIsFull;
};

class SplitScreen extends Component {
  constructor(props) {
    super(props);
    this.rootRef = React.createRef();
    this.wrapperRef = React.createRef();

    const {
      screenSize: { resolutionHeight },
    } = props;
    this.rootFontSize = (resolutionHeight * 3.6) / 100;
  }

  componentDidMount() {
    this.adjustContentToScreen();
  }

  componentDidUpdate() {
    this.adjustContentToScreen();
  }

  adjustContentToScreen = () => {
    let currentRoot = this.rootRef.current;
    let currentWrapper = this.wrapperRef.current;

    const { clientHeight, clientWidth } = currentRoot;
    const {
      stylesTemplate: wrapperGridRows,
      screenSize: { resolutionHeight, resolutionWidth },
    } = this.props;
    const adjust = () => {
      let scale =
        resolutionHeight >= resolutionWidth
          ? clientHeight / resolutionHeight
          : clientWidth / resolutionWidth;

      // Adjust for very narrow viewports
      let yPosition = 0;
      if (clientWidth < resolutionWidth * scale) {
        scale = clientWidth / resolutionWidth;
        const wrapperHeight = currentWrapper.getBoundingClientRect().height;
        yPosition = clientHeight / 2 - wrapperHeight / 2;
      }

      currentWrapper.style.cssText = `
        display: -ms-grid; 
        display: grid;
        -ms-grid-rows: ${wrapperGridRows};  
        grid-template-rows: ${wrapperGridRows};
        width: ${resolutionWidth}px;
        height: ${resolutionHeight}px;
        position: absolute;
        transform: scale(${scale});
        transform-origin: left top;
        font-size: ${this.rootFontSize}px;
      `;

      const wrapperWidth = currentWrapper.getBoundingClientRect().width;

      const xPosition =
        clientWidth > wrapperWidth ? clientWidth / 2 - wrapperWidth / 2 : 0;
      currentWrapper.style.left = `${xPosition}px`;
      currentWrapper.style.top = `${yPosition}px`;
    };

    adjust();
  };

  render() {
    const {
      setCurrentIndex,

      options,
      currentPlaylistIndexSplitScreenFirst,
      currentPlaylistIndexSplitScreenSecond,

      playlistContentSplitScreenFirst,
      playlistContentSplitScreenSecond,
      splitSynchContentFirst,
      splitSynchContentSecond,

      setFirstSynchContent,
      setSecondSynchContent,
      setCurrentViewContent,
      currentViewContent,
      currentViewContentData,
      additionalCurrentViewContent,
      setAdditionalCurrentViewContent,
      synchronizeContentNoApiCall,

      simulatedTime,
      offset,

      activeScreen,
    } = this.props;

    return (
      <StyledSplitScreen ref={this.rootRef}>
        <div ref={this.wrapperRef} className="splitScreenWrapper">
          <DefaultScreen
            screenType="splitFirst"
            simulatedTime={simulatedTime}
            offset={offset}
            setCurrentViewContent={setCurrentViewContent}
            setCurrentIndex={setCurrentIndex}
            playlistContent={playlistContentSplitScreenFirst}
            currentPlaylistIndex={currentPlaylistIndexSplitScreenFirst}
            currentViewContent={currentViewContent}
            activeScreen={activeScreen}
            synchronizeContentNoApiCall={synchronizeContentNoApiCall}
            setCurrentSyncedContent={setFirstSynchContent}
            currentContent={splitSynchContentFirst}
            currentViewContentData={currentViewContentData}
          />
          <DefaultScreen
            screenType="splitSecond"
            simulatedTime={simulatedTime}
            offset={offset}
            setCurrentViewContent={setAdditionalCurrentViewContent}
            setCurrentIndex={setCurrentIndex}
            playlistContent={playlistContentSplitScreenSecond}
            currentPlaylistIndex={currentPlaylistIndexSplitScreenSecond}
            currentViewContent={additionalCurrentViewContent}
            activeScreen={activeScreen}
            synchronizeContentNoApiCall={synchronizeContentNoApiCall}
            setCurrentSyncedContent={setSecondSynchContent}
            currentContent={splitSynchContentSecond}
            currentViewContentData={currentViewContentData}
          />
          <BottomBar
            isFullScreen={isFullScreen(this.props)}
            options={options}
            rootFontSize="0.75em"
          />
        </div>
      </StyledSplitScreen>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentPlaylistIndexSplitScreenFirst: state.view.splitIndexFirst,
    currentPlaylistIndexSplitScreenSecond: state.view.splitIndexSecond,
    splitSynchContentFirst: state.view.splitSynchContentFirst,
    splitSynchContentSecond: state.view.splitSynchContentSecond,
    currentViewContent: state.view.currentViewContent || "",
    options: state.view.activeScreen.options,
    additionalCurrentViewContent: state.view.additionalCurrentViewContent || "",
    stylesTemplate: defineSplitScreenStyles(state, ownProps),
  };
};

const mapDispatchToProps = {
  setFirstSynchContent: setFirstSplitContent,
  setSecondSynchContent: setSecondSplitContent,

  setCurrentViewContent,
  setAdditionalCurrentViewContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(SplitScreen);
