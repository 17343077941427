const isSplitPlaylist = ({ type }) => type === 'split';
const isTheatrePlaylist = ({ type }) => type === 'theatre';

const restructureScreens = (
  screens,
  playlists,
  screenSizeResp,
  collectedProgramme
) =>
  screens.map((screen) => {
    if(!screen.playlists) return;

    const playlist = screen.playlists[0];
    const { type } = playlist;

    if (isSplitPlaylist(playlist)) {
      const {
        options: { firstPlaylistId, secondPlaylistId },
        options,
      } = playlist;

      const screenSize = screenSizeResp.find(
        (size) => size.id === screen.screenSizeId
      );

      const firstPl = playlists.find((pl) => pl.id === firstPlaylistId);
      const secondPl = playlists.find((pl) => pl.id === secondPlaylistId);

      return {
        ...screen,
        screenSize,
        playlists: [firstPl, secondPl],
        options,
        type,
      };
    }

    if (isTheatrePlaylist(playlist)) {
      const {
        options,
      } = playlist;
      const selectedPlaylists = screen.playlists.map(
        (_playlist) => {
          const playlist = playlists.find((pl) => pl.id === _playlist.options.selectedPlaylistId);
          if (playlist)
            return {
              ...playlist,
              options: _playlist.options,
            }
        }
      ).filter(Boolean);

      const emptyPlaylist = {
        contentMeta: [],
        contents: [],
      };

      const screenSize = screenSizeResp.find(
        (size) => size.id === screen.screenSizeId
      );

      const selectedProgrammesIds = screen.playlists.map((p) => p.options?.selectedProgrammeId);

      const programmes = collectedProgramme.filter(
        (programme) => selectedProgrammesIds.includes(programme.id)
      );

      return {
        ...screen,
        playlists: [...selectedPlaylists || emptyPlaylist],
        options,
        type,
        screenSize,
        programmes,
      };
    }

    return { ...screen, type };
  }).filter(s => s);

export default restructureScreens;
