import { SyncDate } from "../../utils/time";
import { getClockOffsetOneTime } from "../../utils/calculate-clock-difference";
import { getOffset, getTimezoneOffset } from "../selectors/app";
import { constants as types } from "../reducers/types";

import * as Sentry from "@sentry/react";

export const syncClockWithServer = () => async (dispatch, getState) => {
  const state = getState();
  const prevOffset = getOffset(state);

  const DateObjectTimezoneOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;

  dispatch(startClockSync());

  try {
    const { timezoneOffset, offset, serverMidnight } =
      await getClockOffsetOneTime();

    // console.log({ timezoneOffset });

    if (timezoneOffset && offset && serverMidnight) {
      localStorage.setItem(
        "serverTime",
        JSON.stringify({
          timezoneOffset,
          offset,
          serverMidnight,
        })
      );

      if (Math.abs(timezoneOffset - DateObjectTimezoneOffsetMs) > 60000) {
        const message = "timezone mismatch";
        console.error(message, timezoneOffset, DateObjectTimezoneOffsetMs);
        Sentry.captureMessage(
          message,
          timezoneOffset,
          DateObjectTimezoneOffsetMs,
          state.app,
          state.view
        );
      }

      SyncDate.setOffset(offset, DateObjectTimezoneOffsetMs);
      dispatch(
        setServerTime({
          timezoneOffset: DateObjectTimezoneOffsetMs,
          offset,
          serverMidnight,
        })
      );
    }
  } catch (error) {
    console.log("Couldn't get server time, trying localstorage", { error });
    const { timezoneOffset, offset, serverMidnight } = JSON.parse(
      localStorage.getItem("serverTime")
    );

    if (Math.abs(timezoneOffset - DateObjectTimezoneOffsetMs) > 60000) {
      const message = "timezone mismatch";
      console.error(message, timezoneOffset, DateObjectTimezoneOffsetMs);
      Sentry.captureMessage(
        message,
        timezoneOffset,
        DateObjectTimezoneOffsetMs,
        state.app,
        state.view
      );
    }

    SyncDate.setOffset(offset, DateObjectTimezoneOffsetMs);
    dispatch(
      setServerTime({
        timezoneOffset: DateObjectTimezoneOffsetMs,
        offset,
        serverMidnight,
      })
    );
  }
};

export function setServerTime(payload = {}) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SERVER_TIME,
      ...payload,
    });
  };
}

export function startClockSync(payload = {}) {
  return {
    type: types.CLOCK_SYNC_START,
    ...payload,
  };
}
