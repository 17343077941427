import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import ProgrammeHeader from "../ProgrammeHeader";
import TodaysSessions from "../TodaysSessions";
import TomorrowSession from "../TomorrowSession";
import BottomBar from "../BottomBar";
import {
  getTodaysSessions,
  getTodaysRemainingSessions,
  getNowSessions,
  getNextSessions,
  getTomorrowList,
} from "../../../redux/selectors/programmes";

const HEADER_HEIGHT_PERCENT = 14;
const BRAND_HEIGHT_PERCENT = 7;

const StyledTheatreScreen = styled.div`
  background-color: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  text-align: center;
  background: #000;
  cursor: none;

  .theatreWrapper {
    // width: 100%!important;
    margin: auto;
    display: grid;
    grid-auto-rows: auto;
    overflow: hidden;
    // background-color: #fff;
  }

  .wrapperBrand {
    background-color: #fff;
    text-align: left;

    .brand {
      height: 100%;
      max-width: 100%;
    }
  }
`;

class TheatreScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.rootRef = React.createRef();
    this.wrapperRef = React.createRef();

    const {
      screenSize: { resolutionHeight },
    } = props;
    this.rootFontSize = (resolutionHeight * 3.6) / 100;
  }

  componentDidMount() {
    this.adjustContentToScreen();
  }

  componentDidUpdate() {
    this.adjustContentToScreen();
  }

  calculateGridRows = () => {
    const {
      options,
      programmes,
      currentTheatre,
      currentViewContent,
      currentPlaylistIndex,
      nextSession,
      nowSession,
    } = this.props;

    const { sizes } = options;
    const { bottomBar } = sizes;

    const { isFullScreen } = currentViewContent || {};
    // const isFullScreen = null;
    const brandContent = (currentTheatre?.image || {}).content;

    //Is the inside playlist content full screen?
    if (isFullScreen) {
      if (brandContent) {
        return "0% 0% 100% 0%";
      }
      return "0% 100% 0%";
    }

    if (brandContent) {
      this.bodyHeightPercent =
        100 - bottomBar - HEADER_HEIGHT_PERCENT - BRAND_HEIGHT_PERCENT;
      return `${HEADER_HEIGHT_PERCENT}% ${BRAND_HEIGHT_PERCENT}% ${this.bodyHeightPercent}% ${bottomBar}%`;
    }

    this.bodyHeightPercent = 100 - bottomBar - HEADER_HEIGHT_PERCENT;
    return `${HEADER_HEIGHT_PERCENT}% ${this.bodyHeightPercent}% ${bottomBar}%`;
  };

  adjustContentToScreen = () => {
    let currentRoot = this.rootRef.current;
    let currentWrapper = this.wrapperRef.current;

    let { clientHeight, clientWidth } = currentRoot;
    let {
      screenSize: { resolutionHeight, resolutionWidth },
    } = this.props;

    const adjust = () => {
      let scale =
        resolutionHeight >= resolutionWidth
          ? clientHeight / resolutionHeight
          : clientWidth / resolutionWidth;

      // Adjust for very narrow viewports
      let yPosition = 0;
      if (clientWidth < resolutionWidth * scale) {
        scale = clientWidth / resolutionWidth;
        const theatreWrapperHeight =
          currentWrapper.getBoundingClientRect().height;
        yPosition = clientHeight / 2 - theatreWrapperHeight / 2;
      }
      // console.log('clientWidth', clientWidth);
      // console.log('resolutionWidth*scale', resolutionWidth * scale);
      // const scale = 1;

      const position = "0%";
      const style = ` 
        width: ${resolutionWidth}px;
        height: ${resolutionHeight}px;
        position: absolute;
        transform: scale(${scale});
        transform-origin: left top;
      `;

      currentRoot.style.cssText = "position: relative;";
      currentWrapper.style.cssText = `${this.calculateWrapperStyle()} ${style}`;
      const viewportWidth = currentRoot.getBoundingClientRect().width;
      const theatreWrapperWidth = currentWrapper.getBoundingClientRect().width;

      const xPosition =
        clientWidth > theatreWrapperWidth
          ? clientWidth / 2 - theatreWrapperWidth / 2
          : 0;
      currentWrapper.style.left = `${xPosition}px`;
      currentWrapper.style.top = `${yPosition}px`;
    };

    adjust();
  };

  calculateWrapperStyle = () => {
    const { screenSize } = this.props;
    const { resolutionHeight, resolutionWidth } = screenSize;

    const wrapperGridTemplateRows = this.calculateGridRows();

    return `
      width: ${resolutionWidth};
      height: ${resolutionHeight};
      display: grid;
      grid-template-rows: ${wrapperGridTemplateRows};
      font-size: ${this.rootFontSize}px;
    `;
  };

  getColumnDistribution = (headerColumns) => {
    if (!headerColumns) return '20% 80%';
    
    const distribution = headerColumns.map(column => `${column.width}%`).join(' ');

    return distribution;
  }

  render() {
    const {
      options,
      programmes,
      todaysSessions,
      tomorrowDayList,
      localTime,
      simulatedTime,
      currentViewContent,
      currentTheatre,
      currentPlaylist,
      activeScreen: {screenSize}
    } = this.props;
    const isLandscape = screenSize.name === "Landscape";
    // const currentTime = simulatedTime.diffMs
    //   ? localTime + simulatedTime.diffMs
    //   : localTime;
    const currentTime = simulatedTime.diffMs
      ? localTime + simulatedTime.diffMs
      : localTime;

    const { isFullScreen } = currentViewContent || {};
    const { fontColor, fontSize, themeColor, sizes, fontFamily } = currentPlaylist?.options || options;

    const brandContent = (currentTheatre?.image || {}).content;
    const hasTodaySessions = todaysSessions.sessions.length !== 0;
    const hasTomorrowSessions = tomorrowDayList.sessions.length !== 0;
    const textFontFamily = fontFamily || "Open Sans";


    const headerProps = {
      fontSize,
      fontColor: fontColor.hex,
      currentTime,
      backgroundColor: themeColor.hex,
      title: currentTheatre?.htmlTitle || currentTheatre?.title,
      fontFamily: textFontFamily,
      isFullScreen,
      columnDistribution: this.getColumnDistribution(currentTheatre?.options?.headerColumns),
      columns: currentTheatre?.options?.headerColumns,
      isLandscape: isLandscape
    };

    const { bottomBar, insidePlaylist } = sizes;

    const isInsidePlBigger60 = insidePlaylist + bottomBar > 60;
    const isInsidePlBigger54 = insidePlaylist + bottomBar > 54;
    const renderHeader = (
      <ProgrammeHeader isFullScreen={isFullScreen} {...headerProps} />
    );

    const renderBrandLogo = brandContent && (
      <div className="wrapperBrand">
        <img
          className="brand"
          src={brandContent}
          alt="Brand Logo"
        />
      </div>
    );

    const renderBody =
      !hasTodaySessions && hasTomorrowSessions ? (
        <TomorrowSession
          isFullScreen={isFullScreen}
          rootFontSize={this.rootFontSize}
          {...this.props}
        />
      ) : (
        <TodaysSessions
          isFullScreen={isFullScreen}
          rootFontSize={this.rootFontSize}
          isInsidePlBigger60={isInsidePlBigger60}
          isInsidePlBigger54={isInsidePlBigger54}
          brandContent={brandContent}
          bodyHeightPercent={this.bodyHeightPercent}
          {...this.props}
        />
      );

    return (
      <StyledTheatreScreen ref={this.rootRef}>
        <div ref={this.wrapperRef} className="theatreWrapper">
          {renderHeader}
          {renderBrandLogo}
          {renderBody}
          <BottomBar options={currentPlaylist?.options || options} rootFontSize="0.70em" />
        </div>
      </StyledTheatreScreen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.view.currentContent,
    localTime: state.view.localTime,
    currentTheatre: state.view.currentTheatre,
    currentPlaylist: state.view.currentPlaylist,
    todaysSessions: getTodaysSessions(state),
    remainingSessionsToday: getTodaysRemainingSessions(state),
    nowSessions: getNowSessions(state),
    nextSessions: getNextSessions(state),
    tomorrowDayList: getTomorrowList(state),
  };
};

export default connect(mapStateToProps)(TheatreScreen);
