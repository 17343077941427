import "react-app-polyfill/ie11";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import reducers from "./redux/reducers";
import { constants as types } from "./redux/reducers/types";

import "./cache";

const environment = process.env.NODE_ENV ? process.env.NODE_ENV : "development";

console.log({ environment });
Sentry.init({
  environment,
  dsn: "https://25c0c2dd22b04074b466ac068025de50@o271674.ingest.sentry.io/5666663",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

console.log({ v: "0.92" });

if (indexedDB) {
  console.log("indexedDB supported");
} else {
  console.log("indexedDB NOT supported");
  // Sentry.captureMessage("indexedDB NOT supported");
}

const composeEnhancers = composeWithDevTools({
  name: "EIApp",
  // actionsBlacklist: [types.SET_CURRENT_VIEW_CONTENT_DATA],

  maxAge: 100,
});
const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(thunk)
    // other store enhancers if any
  )
);

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
