const collectProgrammes = (programmes, sessions) => {
  return programmes.map(programme => {
    const foundMatches = sessions.filter(
      item => item.programmeId === programme.id
    );
    return { ...programme, sessions: foundMatches };
  });
};

const collectSessions = (sessions, speakers) => {
  return sessions.map(session => {
    const foundMatches = speakers.filter(item => item.sessionId === session.id);
    return { ...session, speakers: foundMatches };
  });
};

const getCollectedProgrammes = (programmes, sessions, speakers) => {
  const newSessions = collectSessions(sessions, speakers);
  const newProgrammes = collectProgrammes(programmes, newSessions);

  return newProgrammes;
};

export default getCollectedProgrammes;
