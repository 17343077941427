import createFlatPlaylist from "./create-flat-playlist";
import { SyncDate } from "./time";
import arrayIdInjector from "./array-id-injector";

const getFlatPlaylist = (
  contentMeta,
  contentGroup,
  programmes,
  timezoneOffset,
  offset,
  screenType,
  simulatedTime,
  serverMidnight
) => {
  const startOfCurrentDay = serverMidnight;
  const { startMs } = simulatedTime;

  const isSimulatedTime = startMs ? true : false;

  var startOfSimulatedDay = startMs ? SyncDate.getStartOfDay(startMs) : null;

  const startOfSimulatedDayDateObj = startMs
    ? new Date(startOfSimulatedDay)
    : null;

  // startOfSimulatedDay = startOfSimulatedDay + 60 * 60 * 1000;

  const startPlaylistTime =
    Math.floor((startOfSimulatedDay || startOfCurrentDay) / 1000) * 1000;

  const playlistStartDateObj = new Date(startPlaylistTime);
  const clientTimeNow = new Date();

  const getTimezoneOffsetNow = clientTimeNow.getTimezoneOffset();
  const getTimezoneOffsetSimulated = startMs
    ? startOfSimulatedDayDateObj.getTimezoneOffset()
    : 0;
  const summerTimeDiffSimulatedTime =
    getTimezoneOffsetNow - getTimezoneOffsetSimulated;

  // console.log({
  //   startOfSimulatedDayDateObj,
  //   playlistStartDateObj,
  //   getTimezoneOffsetNow,
  //   getTimezoneOffsetSimulated,
  //   summerTimeDiffSimulatedTime,
  //   clientTimeNow,
  // });

  const dayMs = 86400000;
  const endPlaylistTime = startPlaylistTime + dayMs;

  // const startPlaylistTimeAdjusted = startPlaylistTime;
  // const endPlaylistTimeAdjusted = startPlaylistTimeAdjusted + dayMs;

  const startPlaylistTimeAdjusted = isSimulatedTime
    ? startPlaylistTime + summerTimeDiffSimulatedTime * 60 * 1000
    : startPlaylistTime;
  const endPlaylistTimeAdjusted = startPlaylistTimeAdjusted + dayMs;

  //////// ###################

  // console.log({
  //   simulatedTime: new Date(startMs),
  //   startOfSimulatedDay: startOfSimulatedDay,
  //   SyncDate: SyncDate.getStartOfDay(startMs),
  //   startPlaylistTime: new Date(startPlaylistTime),
  // });

  const entityInjector = (meta) => {
    switch (meta.entityType) {
      case "contentGroup":
        const entity = contentGroup.find((cg) => cg.id === meta.entityId);
        if (!entity) {
          return null;
        }
        return { ...meta, entity };
      case "programme":
        return {
          ...meta,
          entity: {
            ...programmes.find((p) => p.id === meta.entityId),
            type: "programme",
          },
        };
      default:
        return meta;
    }
  };

  function entityFiller(contentData) {
    return contentData
      .map((c) => {
        if (Array.isArray(c)) return arrayIdInjector(entityFiller(c), c.id);
        return entityInjector(c);
      })
      .filter((item) => !!item);
  }

  // array of content meta for supporting multiple playlist
  const filledMeta = contentMeta.map((cm) => entityFiller(cm));
  if (filledMeta[0].length === 0) {
    return contentMeta;
  }

  const flatList = filledMeta.map((item) =>
    createFlatPlaylist(
      item,
      startPlaylistTimeAdjusted,
      endPlaylistTimeAdjusted,
      screenType
    )
  );
  return flatList;
};

export default getFlatPlaylist;
