import _ from 'lodash';
import getNestedContentMeta from './get-nested-content-meta';

export default function flattenScreenPlaylistsContentMeta(screens) {
  return screens.map(screen => ({
    ...screen,
    playlists: screen.playlists.map(playlist => ({
      ...playlist,
      contentMeta: _.flattenDeep(getNestedContentMeta(playlist.contentMeta)),
    })),
  }));
}