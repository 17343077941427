import moment from "moment";

export function getHoursDiff(timeFromMidnight, appEntryTime) {
  const hoursFromMidnightServer = Math.floor(timeFromMidnight / 1000 / 60 / 60);
  const hoursFromMidnight = new Date(appEntryTime);
  return hoursFromMidnight.getHours() - hoursFromMidnightServer;
}

export function getDiffFromTimestamps(
  serverMidnightTimestamp,
  clientMidnightTimestamp
) {
  return clientMidnightTimestamp - serverMidnightTimestamp;
}

export const formatReadable = (date) => {
  return moment(date).format("HH:mm:ss.SSS");
};

export function getTimeFromServerMidnight(
  clientMidnightTimestamp,
  timezoneOffset,
  offset
) {
  return SyncDate.now() - clientMidnightTimestamp + timezoneOffset;
}

export class SyncDate {
  offset = 0;

  // this is current timezone in the client browser
  timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

  static setOffset(offset, timezoneOffset) {
    this.offset = offset || 0;
    this.timezoneOffset = timezoneOffset || 0;
  }

  static getOffset() {
    return this.offset;
  }

  static getTimezoneOffset() {
    return this.timezoneOffset;
  }

  static now() {
    const offset = this.offset ? this.offset : 0;
    return Date.now() + offset;
  }

  static addOffset(date) {
    const offset = this.offset ? this.offset : 0;
    return new Date(date.valueOf() + offset);
  }

  static getClientMidnight() {
    const now = new Date();
    const midnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0,
      0
    );
    return midnight.valueOf();
  }

  static getTimeFromServerMidnight(serverMidnightTimestamp) {
    const offset = this.offset ? this.offset : 0;

    if (serverMidnightTimestamp) {
      return SyncDate.now() - serverMidnightTimestamp;
    }
    return (
      SyncDate.now() -
      SyncDate.getClientMidnight() -
      offset +
      this.timezoneOffset
    );
  }

  static getStartOfDay(timestamp) {
    const offset = this.offset ? this.offset : 0;

    return (
      moment(timestamp).startOf("d").valueOf() + offset - this.timezoneOffset
    );
  }

  static getMoment(timestamp) {
    return moment(timestamp);
  }

  static parseMoment(string, pattern) {
    const offset = this.offset ? this.offset : 0;
    return moment(moment(string, pattern).valueOf() + offset);
  }
}
