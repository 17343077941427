import _ from "lodash";
// import electron from 'electron';

const isContentGroup = ({ entityType }) => entityType === "contentGroup";
const isProgramme = ({ entityType }) => entityType === "programme";

const getContentWithSizes = (contentGroup, screenSize) => {
  const filteredSzs = contentGroup.content.filter((ct) =>
    screenSize.find((sz) => sz.id === ct.screenSizeId)
  ); // filtered for not exist screenId

  const result = filteredSzs.map((content) => {
    const getScreenSizeOfContent = screenSize.find(
      (sz) => sz.id === content.screenSizeId
    );
    const {
      resolutionHeight: height,
      resolutionWidth: width,
    } = getScreenSizeOfContent;

    return getScreenSizeOfContent ? { ...content, height, width } : content;
  });

  return result;
};

const getDeviceSizeContent = (content, deviceScreenSizes) => {
  // const { size: deviceScreenSizes } = electron.screen.getPrimaryDisplay();

  const closestScreenSize = content.reduce((prev, curr) => {
    const { height, width } = deviceScreenSizes;
    return Math.abs(curr.height - height) + Math.abs(curr.width - width) <
      Math.abs(prev.height - height) + Math.abs(prev.width - width)
      ? curr
      : prev;
  });

  return _.omit(closestScreenSize, ["height", "width"]);
};

const addContentEntity = (contentMeta, contentsGroup, screenSize) => {
  const contentGroup = contentsGroup.find(
    (cg) =>
      contentMeta.entityType === "contentGroup" &&
      cg.id === contentMeta.entityId
  );

  const contents = getContentWithSizes(contentGroup, screenSize);

  if (contents.length === 0) return null;

  //No need to filter out other content sizes, for now

  const deviceSizeContent = getDeviceSizeContent(contents, {
    height: 1920,
    width: 1080,
  });

  return deviceSizeContent;
};

const addContentEntityToPlaylist = (screens, contentGroup, screenSizeResp) =>
  screens.map((screen) => {
    const newPlaylists = screen.playlists.map((playlist) => {
      const contents = playlist.contentMeta
        .filter(isContentGroup)
        .map((contentMeta) =>
          addContentEntity(contentMeta, contentGroup, screenSizeResp)
        );

      const programmes = playlist.contentMeta
        .filter(isProgramme)
        .map((item) => ({ ...item, content: "programme" }));
      return { ...playlist, contents: [...contents, ...programmes] };
    });
    // console.log(urls);
    return { ...screen, playlists: newPlaylists };
  });

export default addContentEntityToPlaylist;
