import { constants as types } from "./types";
import { SyncDate } from "../../utils/time";

const initialState = {
  simulatedTime: {},
  isSimulatedTime: false,
  offset: null,
  serverMidnight: null,
  isClockSyncing: true,
};

const actionsMap = {
  [types.SET_SIMULATED_TIME]: (state, action) => {
    const { startMs } = action.payload;

    const simulatedTime = {
      startMs, // timestamp start
      diffMs: startMs - SyncDate.now() + 1000, // adding extra sec to compensate for accurate minutes; difference in milliseconds between simulated time and current time in the moment of submission
    };

    return {
      ...state,
      simulatedTime,
      isSimulatedTime: true,
    };
  },
  [types.CLEAR_SIMULATED_TIME]: (state) => ({
    ...state,
    isSimulatedTime: false,
    simulatedTime: {},
  }),
  [types.SET_SERVER_TIME]: (state, action) => ({
    ...state,
    timezoneOffset: action.timezoneOffset,
    offset: action.offset,
    serverMidnight: action.serverMidnight,
    isClockSyncing: false,
  }),
};

export default function appReducer(state = initialState, action) {
  const reduceFn = actionsMap[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
