import getNestedContentMeta from './get-nested-content-meta';
import arrayIdInjector from './array-id-injector';

function removeEmptyArrays(nestedContentMeta) {
  const { isArray } = Array;
  const isPassFilter = cm => !isArray(cm) || (isArray(cm) && Boolean(cm.length));

  const removedEmptyArrays = nestedContentMeta.filter(isPassFilter);
  return removedEmptyArrays.map(item => {
    if (isArray(item)) {
      return arrayIdInjector(removeEmptyArrays(item), item.id);
    }
    return item;
  }).filter(isPassFilter);
}

const getNestedScreenContentMeta = (screen) =>
  screen.playlists.map(playlist => {
    const nestedContentMeta = removeEmptyArrays(getNestedContentMeta(playlist.contentMeta));
    nestedContentMeta.screenType = screen.type;
    return nestedContentMeta;
  });


export default getNestedScreenContentMeta;
