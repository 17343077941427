import React from "react";

import "./BottomBar.css";

class BottomBar extends React.Component {
  rootRef = React.createRef();

  textRef = React.createRef();
  render() {
    const { options, rootFontSize } = this.props;

    const {
      fontColor,
      themeColor,
      hasBottomBar,
      bottomBarText,
      bottomBarImg,
      bottomBarFontSize,
    } = options;

    if (!hasBottomBar) {
      return null;
    }

    const textColor = !fontColor ? "#000" : fontColor.hex;

    const fontFamily = options.fontFamily || "Open Sans";

    const fontSize = bottomBarFontSize || rootFontSize;

    return (
      <div
        ref={this.rootRef}
        className="bottomBar"
        style={{
          color: textColor,
          backgroundColor: themeColor.hex,
          fontSize: `${fontSize}px`,
          fontFamily,
        }}
      >
        {bottomBarText.length !== 0 && (
          <span ref={this.textRef} className="text">
            {bottomBarText}
          </span>
        )}
        {bottomBarImg && (
          <img
            alt="logo"
            className="img"
            // src={`${bottomBarImg.content}?cache=${
            //   Math.floor(Math.random() * 10000) + 0
            // }`}
            src={bottomBarImg.content}
          />
        )}
      </div>
    );
  }
}

export default BottomBar;
