import React from "react";
import _ from "lodash";
import cn from "classnames";
import { getHtmlWithFonts, safeGet, getIframeDocument } from "../../../utils";
import { StyledSessionItem } from "./styles";

function resizeIframe(iframe) {
  const iframeDocument = getIframeDocument(iframe);
  const clientHeight = safeGet(() => iframeDocument.body.clientHeight, null);
  if (!clientHeight) return;

  iframe.height = `${clientHeight}px`;
}

class SessionItem extends React.Component {
  //     console.log('sessiontitem - componentDidMount');

  constructor(props) {
    super(props);
    this.sessionItemRef = React.createRef();
    this.iframeRef = React.createRef();
    this.throttleSetBottomPoint = _.throttle(
      this.throttleSetBottomPoint.bind(this),
      0
    );
  }

  state = {
    visible: false,
    bottomPoint: 0,
    isImageError: false,
  };

  // componentDidMount() {
  //   const point = this.sessionItemRef.current.getBoundingClientRect().bottom;
  //   // this.setState({
  //   //   ...this.state,
  //   //   bottomPoint: point,
  //   // });
  // }

  componentWillUnmount() {
    this.throttleSetBottomPoint.cancel();
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const point = this.sessionItemRef.current.getBoundingClientRect().bottom;
    if (prevState.bottomPoint !== point) {
      const { session } = this.props;
      return point;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.throttleSetBottomPoint(snapshot);
    }

    if (this.iframeRef.current) {
      this.adjustIframeHeight(this.iframeRef.current);
    }
  }

  throttleSetBottomPoint = (snapshot) => {
    this.setState({
      ...this.state,
      bottomPoint: snapshot,
    });
  };

  hideBrokenLogo = () => {
    this.setState({
      ...this.state,
      isImageError: true,
    });
  }

  adjustIframeHeight = (iframe) => {
    setTimeout(resizeIframe(iframe), 0);
    setTimeout(resizeIframe(iframe), 10);
    setTimeout(resizeIframe(iframe), 100);
  };

  renderSpeakers = () => {
    const {
      session,
      isMinimize,
      isOneSession,
      isInsidePlBigger60,
    } = this.props;
    const { speakers } = session;

    if (!speakers.length) {
      return null;
    }
    const speakersCharsLen = speakers.reduce((acc, speaker) => {
      const speakerText = speaker.name + speaker.job + speaker.company;
      return acc + speakerText;
    }, "").length;

    const resizeSpeakers = speakersCharsLen > 600;

    const speakersClassName = cn({
      ["speakersSession"]: true,
      ["speakersSessionCut"]: isMinimize,
      ["speakersSessionGrow"]:
        isOneSession && !isInsidePlBigger60 && !isMinimize,
    });

    return (
      <div className={speakersClassName}>
        <div style={{ paddingBottom: "0.2em" }}>
          <p>
            {session.speakers
              .slice()
              .sort((a, b) => a.order - b.order)
              .map(({ name, job, company, id }, idx) => (
                <React.Fragment key={id}>
                  <span
                    className="speakerWrapper"
                    style={resizeSpeakers ? { fontSize: `0.3em` } : {}}
                  >
                    {name && (
                      <span
                        style={{ fontWeight: "bold" }}
                        dangerouslySetInnerHTML={{
                          __html: name,
                        }}
                      />
                    )}
                    {job && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `<span style={{ fontWeight: '400' }}>,</span> ${job}`,
                        }}
                      />
                    )}
                    {company && (
                      <span
                        style={{ fontWeight: "bold" }}
                        dangerouslySetInnerHTML={{
                          __html: `<span style={{ fontWeight: '400' }}>,</span> ${company}`,
                        }}
                      />
                    )}
                    {idx === 1 && isMinimize && "..."}
                  </span>
                  <br />

                </React.Fragment>
              ))}
          </p>
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { session, isMinimize, rootFontSize, fontFamily, brandContent, programmes, isOneSession } =
      this.props;

    const { htmlText, hasBody } = getHtmlWithFonts(session.description, {
      rootFontSize,
      fontFamily,
    });
    const autoShortenDescription = programmes && programmes.options && programmes.options.autoShortenDescription || false;
    const descriptionClassName = cn({
      ["descriptionSession"]: true,
      ["autoShortenDescription"]: autoShortenDescription,
    });
    const className = cn({
      ["programmeContentSection"]: true,
      ["programmeContentSectionCut"]: isMinimize,
      ["programmeContentSectionCutTitle"]: isMinimize && brandContent,

      // [styles.speakersSessionSmallSize]: !!brandContent,
    });
    const { isImageError } = this.state;
    return (
      <div className={className}>
        <div>
          <div className="titleSession">
            <span dangerouslySetInnerHTML={{ __html: session.title }} />
            <br />
          </div>
          {this.renderSpeakers()}
          {/* {hasBody && !isMinimize && (
            <div className={descriptionClassName}>
              <span style={{ display: 'block' }} dangerouslySetInnerHTML={{ __html: htmlText }} />

              <div className="descriptionSessionOverlay" />
            </div>
          )} */}
        </div>
        <div className={cn({
          ["imageWrapper"]: true,
          ["imageWrapperHidden"]: isImageError,
        })}>
          <img
            className="image"
            onError={() => this.hideBrokenLogo()}
            src={`${session.image?.content}`}
            alt="logo"
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      session,
      status,
      hex,
      pointPosition,
      isInsidePlBigger54,
      brandContent,
      isMinimize,
      colorText,
      fontFamily,
      fontScalePercentage,
      descriptionLineSpacing,
      titleLineSpacing,
      speakersLineSpacing,
      elementsSpacing,
      programmes,
    } = this.props;
    const { isImageError, bottomPoint } = this.state;

    const descriptionLines = safeGet(() => programmes.options.descriptionLines, 99);
    const isImage = session.image;

    const className = cn({
      ["programmeSection"]: true,
      ["programmeSectionSmallPadding"]:
        isMinimize && isInsidePlBigger54 && brandContent,
    });
    return (
      <StyledSessionItem
        ref={this.sessionItemRef}
        key={session.id}
        fontScalePercentage={fontScalePercentage}
        descriptionLineSpacing={descriptionLineSpacing}
        titleLineSpacing={titleLineSpacing}
        speakersLineSpacing={speakersLineSpacing}
        elementsSpacing={elementsSpacing}
        descriptionLines={descriptionLines}
        className={className}
        style={
          bottomPoint >= pointPosition || bottomPoint == 0
            ? { visibility: "hidden", borderTop: `1px solid ${hex}`, fontFamily }
            : { borderTop: `1px solid ${hex}`, fontFamily }
        }
      >
        <div className="programmeTimeSection">
          {status && (
            <div className="statusWrapper">
              <div
                className="statusProgramme"
                style={{
                  fontFamily,
                  backgroundColor: hex,
                  color: colorText && colorText.hex,
                  paddingTop: fontFamily.includes("Gotham Pro") ? 8 : 2,
                }}
              >
                {status}
              </div>
            </div>
          )}
          <div
            className={cn({
              ["timeProgramme"]: true,
              ["timeProgrammeSmall"]: isMinimize && brandContent,
            })}
          >
            <span className="startTime">{session.start}</span>
            <span> – </span>
            <span className="endTime">{session.end}</span>
          </div>
        </div>
        {this.renderContent()}
      </StyledSessionItem>
    );
  }
}

export default SessionItem;

// 715px 100%
// inside pl 60% must be 429
// bottom bar 10% must be 71
