import PortalApi from "../../utils/eventignitePortalApi";
import MonitoringApi from "../../utils/eventigniteMonitoringApi";

import { SyncDate, formatReadable } from "../../utils/time";

import { constants as types } from "../reducers/types";
import {
  restructureScreens,
  getCollectedProgrammes,
  addContentMetaToPlaylist,
  getNestedScreenContentMeta,
  addContentEntityToPlaylist,
} from "../../utils";

import flattenScreenPlaylistsContentMeta from "../../utils/flatten-screen-playlists-content-meta";

import { setActiveScreen } from "./view";

export function setOpenScreenSelectModal(payload = {}) {
  return {
    type: types.SET_MODAL_OPEN_SCREEN_SELECT,
    payload,
  };
}

export function closeModal() {
  return {
    type: types.CLOSE_MODAL,
  };
}

export function setSimulatedTime(payload = {}) {
  return {
    type: types.SET_SIMULATED_TIME,
    payload,
  };
}

export function clearSimulatedTime(payload = {}) {
  return {
    type: types.CLEAR_SIMULATED_TIME,
    payload,
  };
}

navigator.sayswho = (function () {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
})();

export const pingMonitoringService =
  ({ name, eventSlug, startedAt }) =>
  async (dispatch, getState) => {
    const state = getState();

    const {
      app: {
        timezoneOffset,
        offset,
        isSimulatedTime,
        serverMidnight,
        simulatedTime,
      },
      view: {
        activeScreen,
        localTime,
        splitIndexFirst,
        splitIndexSecond,
        currentIndex,
      },
    } = state;

    // console.log("ping!", activeScreen);
    try {
      const now = new Date();

      const clientMidnight = SyncDate.getClientMidnight();

      const timeOnScreen =
        simulatedTime && simulatedTime.diffMs
          ? localTime + simulatedTime.diffMs
          : localTime;
      const jsDateNew = formatReadable(now);
      const jsDateNewTimezoneOffset =
        new Date().getTimezoneOffset() * 60 * 1000;

      // console.log({ diff: now - syncDateNow, now, syncDateNow });
      await MonitoringApi.post("/ping", {
        name,
        eventSlug,
        timestamp: now.getTime(),
        activeScreenId: activeScreen.id,
        activeScreenName: activeScreen.name,
        timezoneOffset,
        offset,
        startedAt,
        localTime,
        currentIndex,
        splitIndexFirst,
        splitIndexSecond,
        isSimulatedTime,
        serverMidnight,
        clientMidnight,
        timeOnScreen,
        jsDateNew,
        jsDateNewTimezoneOffset,
        formattedTimeOnScreen: formatReadable(new Date(timeOnScreen)),
        browser: navigator.sayswho,
        alertIfOffline: 1,
      });
    } catch (error) {
      // TODO: Error
      console.error({ msg: "failed to ping monitoring server", error });
    }
  };

export const fetchScreensData =
  ({ access_code }) =>
  async (dispatch, getState) => {
    const state = getState();

    const {
      view: { activeScreen },
    } = state;

    // is there a better way of doing this?
    let response = { data: [] };

    try {
      response = await PortalApi.post(`/Events/get-screens`, {
        data: { accessCode: access_code },
      });
    } catch (error) {
      console.error({ msg: "failed to fetch data", error });
    }

    if (!response || !response.data || !response.data[0]) {
      //try localstorage first
      const ds_metadata = localStorage.getItem("ds_metadata");

      // console.log({ ds_metadata });

      if (!ds_metadata) {
        dispatch({
          type: types.FETCH_SCREENS_FAILURE,
        });
        console.log({ m: "FETCH SCREENS FAILURE", response });
        return;
      } else {
        response["data"] = JSON.parse(ds_metadata);
      }
    }

    const {
      screens,
      playlists,
      contentGroup,
      screenSize,
      programmes,
      sessions,
      speakers,
    } = response.data[0];

    localStorage.setItem("ds_metadata", JSON.stringify(response.data));

    // console.log(response.data[0]);

    dispatch({
      type: types.FETCH_USER_DATA_SUCCESS,
      payload: response.data[0],
    });

    // try {

    // TODO: Is this even needed? Planning to use Service Workers to cache the request
    // saveJSONtoOfflineDB({ db, data: response.data[0] });

    // storeResourcestoOfflineDB({ db, data: response.data[0] });

    // } catch (error) {
    //   console.error("DB error when trying to save offline resources ", error);
    // }

    // Save to Dexie

    const collectedProgramme = getCollectedProgrammes(
      programmes,
      sessions,
      speakers
    );

    const screensWithPlaylistOptionsAdded = restructureScreens(
      screens,
      playlists,
      screenSize,
      collectedProgramme
    );
    
    // Add Content Metadata to nested Playlist references
    const addedContentMetaToPlaylist = addContentMetaToPlaylist(
      screensWithPlaylistOptionsAdded,
      playlists
      );
      
      const screensWithNestedContentMeta = addedContentMetaToPlaylist.map(
        (screen) => ({
          ...screen,
          nestedContentMeta: getNestedScreenContentMeta(screen),
        })
        );

    const screensWithFlattenPlaylistsContentMeta =
      flattenScreenPlaylistsContentMeta(screensWithNestedContentMeta);

    // ?
    const screensWithAddedContentEntityToPlaylist = addContentEntityToPlaylist(
      screensWithFlattenPlaylistsContentMeta,
      contentGroup,
      screenSize
    );

    // console.log('SCREENS:', screensWithAddedContentEntityToPlaylist);
    // const screensWithMesssageUrlReplaced = extractHtmlContentFromUrl(
    //   screensWithAddedContentEntityToPlaylist
    // );
    dispatch({
      type: types.FETCH_SCREENS_SUCCESS,
      payload: screensWithAddedContentEntityToPlaylist,
    });

    // await fetchHtmlContentFromUrls(
    //   screensWithAddedContentEntityToPlaylist,
    //   dispatch
    // );

    if (activeScreen) {
      screensWithAddedContentEntityToPlaylist.map((screen) => {
        if (screen.id === activeScreen.id) dispatch(setActiveScreen(screen));
      });
    }
  };
