import React, { useCallback, Fragment } from "react";
import { connect } from "react-redux";
import ModalBackdrop from "./ModalBackdrop";
import SelectScreenModal from "./SelectScreenModal";
import { closeModal } from "../../../redux/actions/";

const ModalSelector = (props) => {
  const { modalOpen, modalOptions, closeModal } = props;

  const printModal = useCallback(() => {
    switch (modalOpen) {
      case "selectScreenModal":
        return (
          <SelectScreenModal
            open
            modalOptions={modalOptions}
            hasCloseIcon={true}
            onClose={closeModal}
          />
        );

      default:
    }
  }, [modalOpen, closeModal, modalOptions]);

  return (
    <Fragment>
      {modalOpen && <ModalBackdrop open />}
      {printModal()}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    modalOpen: state.modal.openModal,
    modalOptions: state.modal.modalOptions,
  };
};

export default connect(mapStateToProps, { closeModal })(ModalSelector);
