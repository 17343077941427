import React, { Component } from "react";
import cn from "classnames";
import path from "path";
import "./Slide.css";
import _ from "lodash";

import htmlFromUrl from "../../../utils/html-from-url";

class Slide extends Component {
  constructor() {
    super();
    this.state = {};

    this.vidRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.viewContent &&
      this.props.viewContent === nextProps.viewContent
    )
      return false;

    return true;
  }

  componentWillUnmount() {
    clearInterval(this.animateOpacity);
  }

  selectContent = (extension, view) => {
    const { opacity, height } = this.state;
    const { showElements, currentViewContentData, enabledSound } = this.props;

    // <span dangerouslySetInnerHTML={{ __html: htmlText }} />;
    // const styles =
    //   HEIGHT_CALCULATED_TIMES === 10 ? { opacity, height } : { opacity };

    const styles = { opacity };

    //  console.log(styles);

    //temp hack! TODO ###################################################
    if (!extension) extension = ".html";

    const currentGlobalCacheItems = _.concat(
      global.fileCache.mainPlaylistCache,
      global.fileCache.secondaryPlaylistCache
    );

    // const cachedContent =
    //   currentViewContentData &&
    //   currentViewContentData.find((cc) => {
    //     return cc && cc.content === view && cc.objectURL;
    //   });

    const cachedContent =
      currentGlobalCacheItems &&
      currentGlobalCacheItems.find((cc) => {
        return cc && cc.content === view && cc.objectURL;
      });

    if (!cachedContent && view !== "programme") {
      // console.log("Cache not found: ", view, { currentViewContentData });
    }

    // console.log(view, { cachedContent });

    //   src={cachedContent && cachedContent.data ? cachedContent.data : view}
    switch (extension) {
      case ".mp4":
        // console.log({ opacity, view });
        return (
          <React.Fragment>
            <video
              ref={this.vidRef}
              className="transitionWrapper video"
              style={{ opacity }}
              autoPlay
              muted={!enabledSound}
              playsInline
            >
              <source
                src={
                  cachedContent && cachedContent.objectURL
                    ? cachedContent.objectURL
                    : view
                }
                type="video/mp4"
              />
            </video>
          </React.Fragment>
        );
      case ".jpeg":
      case ".jpg":
      case ".png":
      case ".webp":
        return (
          <div className="transitionWrapper" key={view}>
            <img
              style={{ opacity, maxWidth: "100%", maxHeight: "100%" }}
              className={styles.image}
              src={
                cachedContent && cachedContent.objectURL
                  ? cachedContent.objectURL
                  : view
              }
              alt="image"
            />
          </div>
        );
      case ".html":
      case ".htm":
        return cachedContent && cachedContent.data ? (
          <div
            style={{ opacity }}
            dangerouslySetInnerHTML={{
              __html: cachedContent.data,
            }}
          />
        ) : (
          <iframe
            style={{ border: 0, borderRight: '1px solid' }}
            width="100%"
            height="100%"
            src={view}
          ></iframe>
        );
      default:
        return <div></div>;
    }
  };

  render() {
    const { viewContent, forwardedRef, justifyContent = "center" } = this.props;
    const getExtension = path.extname(viewContent).toLowerCase();

    const className = cn({
      ["slide"]: true,
      ["slideJustifyLeft"]: justifyContent === "left",
      ["slideJustifyCenter"]: justifyContent === "center",
      ["slideJustifyRight"]: justifyContent === "right",
    });

    // console.log("slide render!");

    return (
      <div ref={forwardedRef} className={className}>
        {this.selectContent(getExtension, viewContent)}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Slide {...props} forwardedRef={ref} />
));
