import _ from 'lodash';
import { constants as types } from './types';

const initialState = {
  screensList: null,
};

const actionMap = {
  [types.FETCH_USER_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      clientData: payload,
    };
  },
};

export default function userReducer(state = initialState, action) {
  const reduceFn = actionMap[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
