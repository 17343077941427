import React, { Component } from "react";
import SessionItem from "../SessionItem";
import { StyledSessionList } from "./styles";
import SessionItemLandscape from "../SessionItemLandscape";

export default class SessionList extends Component {
    render() {
        const {
            fontFamily,
            hex,
            sessionList,
            rootFontSize,
            areSessionsLeft,
            pointPosition,
            fontScalePercentage,
            descriptionLineSpacing,
            speakersLineSpacing,
            titleLineSpacing,
            elementsSpacing,
            programmes,
            isLandscape,
        } = this.props;

        return (
            <StyledSessionList
                titleLineSpacing={titleLineSpacing}
                descriptionLineSpacing={descriptionLineSpacing}
                speakersLineSpacing={speakersLineSpacing}
                elementsSpacing={elementsSpacing}
                fontFamily={fontFamily}
            >
                {areSessionsLeft ? (
                    sessionList.map((session) => {
                        return isLandscape ? (
                            <SessionItemLandscape
                                rootFontSize={rootFontSize}
                                fontScalePercentage={fontScalePercentage}
                                titleLineSpacing={titleLineSpacing}
                                descriptionLineSpacing={descriptionLineSpacing}
                                speakersLineSpacing={speakersLineSpacing}
                                elementsSpacing={elementsSpacing}
                                fontFamily={fontFamily}
                                key={session.id}
                                hex={hex}
                                session={session}
                                pointPosition={pointPosition}
                                programmes={programmes}
                            />
                        ) : (
                            <SessionItem
                                rootFontSize={rootFontSize}
                                fontScalePercentage={fontScalePercentage}
                                titleLineSpacing={titleLineSpacing}
                                descriptionLineSpacing={descriptionLineSpacing}
                                speakersLineSpacing={speakersLineSpacing}
                                elementsSpacing={elementsSpacing}
                                fontFamily={fontFamily}
                                key={session.id}
                                hex={hex}
                                session={session}
                                pointPosition={pointPosition}
                                programmes={programmes}
                            />
                        )
                    })
                ) : (
                    <div className="endText">
                        <span>All sessions are finished.</span>
                        <span>Thank you!</span>
                    </div>
                )}
            </StyledSessionList>
        );
    }
}
