import React, { Component } from "react";
import styled from "styled-components";
import { SyncDate } from "../../../utils/time";

// const StyledTime = styled.div`
//   font-family: ${fontFamily};
//   font-size: 1em;
//   text-align: center;
//   color: ${colorText};
// `;

// const StyledWeek = styled.div`
//   font-family: ${fontFamily};
//   font-size: 0.5em;
//   text-align: center;
// `;

const StyledTime = styled.div`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "inherit")};
  font-size: 1.5em;
  text-align: center;
  color: ${(props) => (props.colorText ? props.colorText : "inherit")};
`;

const StyledWeek = styled.div`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "inherit")};
  font-size: 1em;
  text-align: center;
  color: ${(props) => (props.colorText ? props.colorText : "inherit")};
`;

export default class LandscapeClock extends Component {
  state = {
    date: SyncDate.now(),
  };

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: SyncDate.now(),
    });
  }

  render() {
    const { date } = this.state;
    const { currentTime, colorText, day, fontFamily } = this.props;

    return (
      <div style={{ color: "#fff" }}>
        <StyledTime fontFamily={fontFamily} colorText={colorText}>
          {day || SyncDate.getMoment(currentTime || date).format("HH:mm")}
        </StyledTime>
        <StyledWeek fontFamily={fontFamily} colorText={colorText}>
          {SyncDate.getMoment(currentTime || date).format("dddd")}
        </StyledWeek>
      </div>
    );
  }
}
